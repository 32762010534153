import { Spacer, Text, Title } from '@color/continuum';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';

export const enum NextStepsType {
  Accepted,
  Challenged,
}

export interface NextStepsProps {
  type: NextStepsType;
  date: string;
}

export const NextSteps: React.FC<NextStepsProps> = (props: NextStepsProps) => {
  const { type, date } = props;
  let incompleteStep;
  let completeStep;
  if (type === NextStepsType.Accepted) {
    incompleteStep =
      'Need to re-submit health-related data file with revised variant interpretation';
    completeStep = `Accepted All of Us variant interpretation ${date}`;
  } else {
    // Fallthrough to NextStepsType.Challenged
    incompleteStep = 'Variant will be reviewed by the Variant Adjudication Committee';
    completeStep = `Challenged All of Us variant interpretation ${date}`;
  }

  return (
    <>
      <Title variant="headline3">Next Steps</Title>
      <Spacer variant="xSmall" />

      <Text>
        <AccessTimeIcon height="20px" /> {incompleteStep}
      </Text>
      <Text>
        <CheckIcon height="20px" /> {completeStep}
      </Text>
    </>
  );
};
