import { Box, Button, Spacer, Stack, Text } from '@color/continuum';
import { useLocalStorageState } from '@color/lib';
import { useEffect } from 'react';

import welcomeLogo from 'assets/images/welcome-logo.svg';
import { Logo } from 'components/App/Nav/Logo';
import { OidcProvider } from 'lib/types';

import { Page } from '../common/Page';

interface Props {
  onLogin: () => void;
  oidcProvider: OidcProvider;
  setOidcProvider: (provider: OidcProvider) => void;
}

/**
 * Note: Tests for Login are done in the App.test.txs file
 * To verify the entire app has working login flow
 */
export const Login: React.FC<Props> = (props) => {
  const { onLogin, oidcProvider, setOidcProvider } = props;
  const [storedOidcProvider, setStoredOidcProvider] = useLocalStorageState<
    OidcProvider | undefined
  >('oidcProvider', undefined);

  // Wait for a rerender of AuthProvider with the selected OIDC provider configuration
  // the "onLogin" function needs to be updated with the selected OIDC settings
  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    if (oidcProvider !== undefined) {
      onLogin();
    }
  }, [oidcProvider, storedOidcProvider, onLogin]);

  return (
    <Page requiresAuth={false}>
      <Spacer variant="xxLarge" />
      <Box display="flex" justifyContent="center">
        <Logo />
      </Box>
      <Spacer variant="xxLarge" />
      <Box display="flex" justifyContent="center">
        <img src={welcomeLogo} alt="cartoon of All of Us" />
      </Box>
      <Spacer variant="xLarge" />
      <Box display="flex" justifyContent="center">
        <Text variant="body1" bold>
          Welcome to the <i> All of Us </i> Report Harmonization Platform.
        </Text>
      </Box>
      <Spacer variant="large" />
      <Box display="flex" justifyContent="center">
        <Stack gap={1}>
          {/* randomly using 620px for max width, could be changed if Design/Product asks */}
          <Text variant="body1" maxWidth={620}>
            You are accessing a U.S. Government information system which may contain information
            that must be protected under the U.S. Privacy Act or other sensitive information and is
            intended for authorized use only. Information system usage may be monitored, recorded,
            and subject to audit. Unauthorized attempts to upload information, download information,
            change information, or use of this information system is prohibited and subject to
            criminal and civil penalties. Use of this information system indicates consent to
            monitoring and recording.
          </Text>
        </Stack>
      </Box>
      <Spacer variant="large" />
      <Box display="flex" justifyContent="center">
        <Stack gap={2}>
          <Button
            variant="color-primary"
            fullWidthBreakpoint="sm"
            onClick={() => {
              setOidcProvider('google');
              setStoredOidcProvider('google');
            }}
          >
            Sign in with Google
          </Button>
          <Button
            variant="color-primary"
            fullWidthBreakpoint="sm"
            onClick={() => {
              setOidcProvider('microsoft');
              setStoredOidcProvider('microsoft');
            }}
          >
            Sign in with Microsoft
          </Button>
        </Stack>
      </Box>
    </Page>
  );
};
