import { Box, FormikSubmitButton, FormikTextField } from '@color/continuum';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { OTP_PATH_URL_KEY, usePostWithAuth } from 'lib/api';

export const MfaVerify: React.FC = () => {
  const postWithAuth = usePostWithAuth();
  return (
    <Formik
      initialValues={{
        securityCode: '',
      }}
      validationSchema={Yup.object().shape({
        securityCode: Yup.string().required('Required').length(6, 'Must be 6 characters'),
      })}
      onSubmit={async (values, { setErrors }) => {
        const response = (await postWithAuth('/mfa/verify', {
          token: values.securityCode,
        })) as any;
        if (response.isVerified) {
          window.sessionStorage.setItem(OTP_PATH_URL_KEY, '');
          window.location.href = '/dashboard';
        }
        setErrors({ securityCode: 'Incorrect code, please try again' });
      }}
    >
      <Form>
        <Box display="flex" justifyContent="center" gap={3}>
          <FormikTextField
            sx={{ width: 250 }}
            label="Enter your security code"
            hiddenLabel={false}
            name="securityCode"
            required
            requiredIndicatorIsEnabled
            type="text"
          />
          <FormikSubmitButton isLoading={false}>Submit</FormikSubmitButton>
        </Box>
      </Form>
    </Formik>
  );
};
