import { Box, Container, Divider, Spacer, Table, Text, Title } from '@color/continuum';
import { useParams } from 'react-router-dom';

import {
  NonReportableVariantsTable,
  VariantTableBody,
  variantTableColumns,
} from 'components/Reports/ReportDetails/NonReportableVariantsTable';
import { VariantsTabClassifications } from 'components/Reports/ReportDetails/VariantsTabClassifications';
import { useColorQuery } from 'lib/api';
import { Variant } from 'lib/types';

export const VariantsTab = () => {
  const { id: reportId } = useParams<{ id: string }>();
  const {
    isLoading,
    isError,
    error,
    data: variants,
  } = useColorQuery<{ results: Variant[] }>(`/reports/${reportId}/variants/reportable`);
  if (isError) {
    return <Text>{`${error}`}</Text>;
  }
  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  // @rohittalwalkar - useColorQuery might return "undefined"
  if (variants === undefined || variants.results === undefined) {
    return <Text>Loading...</Text>;
  }

  return (
    <Container>
      <Spacer variant="xLarge" />
      <Box>
        <Title>Reportable Variants</Title>
        <Table
          columns={variantTableColumns}
          isLoading={false}
          tableOptions={{ sortDirections: {}, setSortDirections: () => {} }}
        >
          {variants.results.length === 0 && <Text>There are no informative variants.</Text>}
          {variants.results.length > 0 && <VariantTableBody variants={variants.results} />}
        </Table>
        <Spacer variant="medium" />
        <Divider aria-hidden="true" />
        <Spacer variant="medium" />
        <NonReportableVariantsTable />
      </Box>
      <Spacer variant="xxxLarge" />
      {variants.results.length > 0 && <VariantsTabClassifications variants={variants.results} />}
    </Container>
  );
};
