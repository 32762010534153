import { Box, PageContainer, Spacer, Text, Title } from '@color/continuum';
import { useAuth } from 'react-oidc-context';

import { DisplayError } from 'components/DisplayError';
import { UserInfo, useColorQuery } from 'lib/api';
import { CAN_MANAGE_REPORTS } from 'lib/constants';

import { Page } from '../common/Page';
import { ReportTaskCards } from './ReportTaskCards';
import { VariantTaskCards } from './VariantTaskCards';

export const Dashboard: React.FC = () => {
  const auth = useAuth();
  const userInfoQuery = useColorQuery<UserInfo>(`/userinfo`);

  if (userInfoQuery.isError) {
    return <DisplayError {...userInfoQuery.error} />;
  }
  if (userInfoQuery.isLoading) {
    return <Text>Loading...</Text>;
  }
  if (userInfoQuery.isSuccess) {
    const userInfo = userInfoQuery.data;

    return (
      <Page requiresAuth>
        <PageContainer helmetTitle="Variants">
          {/* TODO: remove this extra spacer when the Appbar is migrated to shared Appbar component (https://getcolor.atlassian.net/browse/PC-162) */}
          <Spacer variant="xxLarge" />
          <Title sx={{ textAlign: 'center' }}>Hello {auth.user?.profile.name}</Title>
          <Spacer variant="large" />
          <Title>Tasks</Title>
          <Box sx={{ paddingY: 3 }}>
            {userInfo.permissions.includes(CAN_MANAGE_REPORTS) && <ReportTaskCards />}
            <VariantTaskCards userInfo={userInfo} />
          </Box>
        </PageContainer>
      </Page>
    );
  }
  return <Text>Loading...</Text>;
};
