import {
  Box,
  Column,
  DjangoTableWithPagination,
  IPaginatedResponse,
  SortDirections,
  Text,
  Title,
} from '@color/continuum';
import { camelCase, mapKeys } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { DisplayError } from 'components/DisplayError';
import { useColorPaginatedQuery } from 'lib/api';

import { ReporthHistoryTableBody } from './ReportHistoryTableBody';

const historyTableColumns: Column[] = [
  {
    id: 'date',
    name: 'Date',
    isSortable: false,
  },
  {
    id: 'event',
    name: 'Event',
    isSortable: false,
  },
  {
    id: 'note',
    name: 'Notes',
    isSortable: false,
  },
  {
    id: 'actor',
    name: 'Actor',
    isSortable: false,
  },
];

export const ReportHistoryTab: React.FC = () => {
  const { id: reportId } = useParams<{ id: string }>();

  const {
    isLoading,
    isError,
    error,
    data: paginatedData,
    tableOptions,
    paginationOptions,
  } = useColorPaginatedQuery<any>(`/reports/${reportId}/history`);

  const [sortDirections] = useState<SortDirections>({});

  const { setSortDirections } = tableOptions;

  let eventList = <Text />;
  if (isError) {
    eventList = <DisplayError {...error} />;
  } else if (isLoading) {
    eventList = <Text>Loading...</Text>;
  } else if (!paginatedData?.results.length) {
    eventList = <Text>There is no history for this report.</Text>;
  } else {
    const djangoResponse: IPaginatedResponse<any> = {
      ...paginatedData,
      results: paginatedData!.results.map((rawEvent) =>
        mapKeys(rawEvent, (value, key) => camelCase(key))
      ) as unknown as any[],
    };
    const paginationProps = { rowsPerPageOptions: [50, 100, 500] };
    const tableProps = {
      isLoading,
      tableOptions: { sortDirections, setSortDirections },
      paginationOptions: { ...paginationOptions, ...{ rowsPerPage: 50 } },
      djangoResponse,
      paginationProps,
    };
    eventList = (
      <DjangoTableWithPagination {...{ columns: historyTableColumns, ...tableProps }}>
        <ReporthHistoryTableBody events={djangoResponse.results} />
      </DjangoTableWithPagination>
    );
  }
  return (
    <Box>
      <Title>History</Title>
      {eventList}
    </Box>
  );
};
