import { Alert, AlertColor, AlertTitle, Card, CardContent, Spacer } from '@color/continuum';
import { ReactNode } from 'react';

export interface IAlertCardProps {
  alertSeverity: AlertColor;
  alertTitle: ReactNode;
  children: ReactNode;
}

export const AlertCard: React.FC<IAlertCardProps> = (props) => {
  const { alertSeverity, alertTitle, children } = props;

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: '#F9F9FA',
        border: '1px solid #EAEAEB',
        borderRadius: '8px',
        maxWidth: '750px',
        padding: 3,
      }}
    >
      <CardContent
        sx={{
          padding: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Alert severity={alertSeverity}>
          <AlertTitle>{alertTitle}</AlertTitle>
        </Alert>
        <Spacer variant="medium" />
        {children}
      </CardContent>
    </Card>
  );
};
