import { Environment } from '@color/lib';

import { AppConfig } from './types';

export const config: AppConfig = {
  API_ROOT: '/api/v1',
  ENVIRONMENT: Environment.STAGING,
  LAUNCHDARKLY_CLIENT_ID: '63091c4b8336f110f77b13ba',
  SENTRY_DSN: 'https://ae958ab41bf741f2859b1833e9133f52@o22410.ingest.sentry.io/6109068',
  USE_REFRESH_TOKENS: true,
  VARIANT_JOTFORM: 'https://color.jotform.com/240525329610046',
  OIDC_PROVIDERS: {
    google: {
      authority: 'https://accounts.google.com',
      client_id: '873731912317-vosestmc9fflae3qnop5sk1a7c3i8e6f.apps.googleusercontent.com',
      client_secret: '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', // Not an actual secret (SPA client)
    },
    microsoft: {
      authority: 'https://login.microsoftonline.com/common/v2.0',
      client_id: '82a38c4e-5fea-41eb-802d-293cc2be3693',
      client_secret: null,
    },
    auth0: {
      authority: 'https://aou-rhp-dev.us.auth0.com',
      client_id: 'VVK6oNKuBCb2REtXuidT2eHUCe5n3ZIN',
      client_secret: null,
    },
  },
};
