import { Spacer, Text } from '@color/continuum';
import { camelCase } from 'lodash';
import { Fragment } from 'react';

import { DisplayError } from 'components/DisplayError';
import { useColorQuery } from 'lib/api';
import { VariantTask } from 'lib/types';

import { ChallengedVariantSummaryCard } from './ChallengedVariantSummaryCard';
import { VacMemberTaskCard } from './VacMemberTaskCard';

interface QueryResults {
  results: VariantTask[];
}

export const VacMemberTaskCards: React.FC = () => {
  const variantsQuery = useColorQuery<QueryResults>(`/extendedvariants/vac_tasks`);
  if (variantsQuery.isError) {
    return <DisplayError {...variantsQuery.error} />;
  }
  if (variantsQuery.isLoading || variantsQuery.isIdle) {
    return <Text>Loading...</Text>;
  }
  // If other checks are false, variants.isSuccess == true
  const vacMemberTasks = variantsQuery.data.results.map((task) => {
    // eslint-disable-next-line no-param-reassign
    task.type = camelCase(task.type);
    return task;
  });
  // As of PC-1531, we render a single summary card for all VAC resolve tasks
  const pendingChallenges = vacMemberTasks.filter((task) => task.type === 'unresolvedChallenge');
  const nonChallengeTasks = vacMemberTasks.filter((task) => task.type !== 'unresolvedChallenge');
  return (
    <>
      {pendingChallenges.length > 0 && (
        <>
          <Spacer variant="small" />
          <ChallengedVariantSummaryCard
            variantIds={pendingChallenges.map((task) => task.variant.id)}
            pendingChallenges={pendingChallenges.length}
          />
        </>
      )}
      {nonChallengeTasks.map((vacTask) => (
        <Fragment key={vacTask.type + vacTask.variant.id}>
          <Spacer variant="small" />
          <VacMemberTaskCard task={vacTask} type={vacTask.type} />
        </Fragment>
      ))}
    </>
  );
};
