import { useLocalStorageState } from '@color/lib';
import { WebStorageStateStore } from 'oidc-client-ts';
import { ReactNode } from 'react';
import { AuthProvider as OIDCAuthProvider } from 'react-oidc-context';

import { config } from 'config';
import { OidcProvider } from 'lib/types';

interface Props {
  oidcProvider: OidcProvider;
  children?: ReactNode;
}

interface AdditionalProps {
  useRefreshTokens?: boolean;
}

/**
 * Makes an Auth0-backed user session (in the appropriate environment) available.
 *
 * To interact with the provided context, call the `useAuth0()` hook.
 *
 * This provider should be nested beneath `BrowserRouter` or similar:
 *
 * > The `Auth0Provider` remembers where the user wanted to go and, if
 * > authentication were successful, it takes the user to that route. As such,
 * > the `Auth0Provider` needs to have access to the session history of the
 * > application... you need to wrap the Auth0Provider with BrowserRouter.
 */
export const AuthProvider = ({ oidcProvider, children }: Props) => {
  // If a login must be triggered, where should users be redirected back to?
  // Note that the URL must be explicitly allowed in the Auth0 configuration.
  // The simplest option is to just use the originating page.
  const redirectUri = window.location.origin;

  const additionalProps: AdditionalProps = {};
  if (config.USE_REFRESH_TOKENS) {
    additionalProps.useRefreshTokens = true;
  }

  // We store the OIDC selection as a prop to rerender AuthProvider and update the OIDC configuration
  // We also store the selection in local storage so it persists across the redirects in the OIDC flow
  // We use local storage instead of session storage to keep users logged in if they open a new tab
  const [storedOidcProvider] = useLocalStorageState<OidcProvider | undefined>(
    'oidcProvider',
    undefined
  );
  const storedOrFromPropsOidcProvider = oidcProvider || storedOidcProvider;

  if (storedOrFromPropsOidcProvider === undefined) {
    return <OIDCAuthProvider key={oidcProvider}>{children}</OIDCAuthProvider>;
  }

  // See https://github.com/authts/oidc-client-ts/blob/main/src/OidcClientSettings.ts for all available settings
  return (
    <OIDCAuthProvider
      redirect_uri={redirectUri}
      scope="openid profile email"
      // TODO: specify acr_values to rely on IdP MFA once all OIDC providers support it
      // (acr_values may have to be specific to each IdP)
      // acr_values=...
      {...config.OIDC_PROVIDERS[storedOrFromPropsOidcProvider]}
      {...additionalProps}
      userStore={new WebStorageStateStore({ store: window.localStorage })}
      key={oidcProvider}
    >
      {children}
    </OIDCAuthProvider>
  );
};
