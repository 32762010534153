import { Environment, getEnvironmentOrDefault } from '@color/lib';

import { AppConfig, AppEnvironment } from './types';

/* eslint-disable global-require */
const ENVIRONMENT_CONFIGS: Record<AppEnvironment, () => AppConfig> = {
  [Environment.LOCAL]: () => require('./local').config,
  [Environment.STAGING]: () => require('./staging').config,
  [Environment.PRODUCTION]: () => require('./production').config,
};

const environment = getEnvironmentOrDefault(Environment.LOCAL) as AppEnvironment;

export const config: AppConfig = ENVIRONMENT_CONFIGS[environment]();
