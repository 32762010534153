import { Image } from '@color/continuum';
import { Link } from 'react-router-dom';

import logo from 'assets/images/all-of-us-logo.png';

export const Logo = () => (
  <Link
    to="/"
    style={{
      // FIXME https://getcolor.atlassian.net/browse/UXTASK-1363
      // convert to `ExternalLink` or similar, and then use `sx`
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Image
      src={logo}
      alt="All of Us logo"
      sx={{
        marginX: 3,
        marginY: 0,
        width: '120px',
      }}
    />
  </Link>
);
