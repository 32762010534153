import { Spacer, Text } from '@color/continuum';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Fragment } from 'react';

import { DisplayError } from 'components/DisplayError';
import { useColorQuery } from 'lib/api';
import { ReportTask } from 'lib/types';

import { ManualHoldSummaryCard } from './ManualHoldSummaryCard';
import { ReportTaskCard } from './ReportTaskCard';

interface QueryResults {
  results: ReportTask[];
}

export const ReportTaskCards: React.FC = () => {
  const reportsQuery = useColorQuery<QueryResults>(`/reports/tasks`);
  const { useNewDashboardApi } = useFlags();

  if (reportsQuery.isError) {
    return <DisplayError {...reportsQuery.error} />;
  }
  if (reportsQuery.isLoading) {
    return <Text>Loading...</Text>;
  }
  if (reportsQuery.isSuccess) {
    const reportTasks = reportsQuery.data.results;
    return (
      <>
        {reportTasks.map((reportTask) => (
          <Fragment key={reportTask.type}>
            <Spacer variant="small" />
            <ReportTaskCard task={reportTask} />
          </Fragment>
        ))}
        {useNewDashboardApi && (
          <>
            <Spacer variant="small" />
            <ManualHoldSummaryCard />{' '}
          </>
        )}
      </>
    );
  }
  return <Text>Loading...</Text>;
};
