import { Box, PaginationOptions, TableOptions, TableProps, Text } from '@color/continuum';
import WarningIcon from '@mui/icons-material/Warning';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
} from '@mui/x-data-grid-pro';

import { REPORT_STATUS_NAMES, Report, ReportStatus } from 'lib/types';
import { formatDateString, getReportSignificanceText, openReportInNewTab } from 'lib/utils';

const renderCell = (cell: GridRenderCellParams) => <Text>{cell.value}</Text>;
const renderTestType = (cell: GridRenderCellParams) => {
  return (
    <Box>
      {cell.row.revisionReason && (
        <Text bold>
          <WarningIcon
            sx={(theme) => ({
              color: theme.palette.warning.main,
              marginBottom: '-5px',
            })}
          />
          Revised
        </Text>
      )}

      <Text>{cell.value}</Text>
    </Box>
  );
};
const renderStatus = (cell: GridRenderCellParams) => {
  const isManualHold = cell.value === 'Manual hold';
  return (
    <Box title={cell.row.holdReason}>
      <Text
        sx={(theme) => ({
          color: isManualHold ? theme.palette.error.main : theme.palette.text.primary,
        })}
      >
        {cell.value}
      </Text>
      {isManualHold && cell.row.holdReason && <Text>Reason: {cell.row.holdReason}</Text>}
    </Box>
  );
};

const columns: GridColDef[] = [
  {
    field: 'date',
    flex: 0.5,
    renderCell,
    renderHeader: () => (
      <Text>
        <b>Date</b>
      </Text>
    ),
    sortable: false,
  },
  {
    field: 'id',
    flex: 0.7,
    renderCell,
    renderHeader: () => (
      <Text>
        <b>Report ID</b>
      </Text>
    ),
    sortable: false,
  },
  {
    field: 'sampleId',
    flex: 0.7,
    renderCell,
    renderHeader: () => (
      <Text>
        <b>Sample ID</b>
      </Text>
    ),
    sortable: false,
  },
  {
    field: 'biobankId',
    flex: 0.7,
    renderCell,
    renderHeader: () => (
      <Text>
        <b>Biobank ID</b>
      </Text>
    ),
    sortable: false,
  },

  {
    field: 'analysisType',
    flex: 0.5,
    renderCell: renderTestType,
    renderHeader: () => (
      <Text>
        <b>Test</b>
      </Text>
    ),
    sortable: false,
  },
  {
    field: 'significance',
    flex: 1.2,
    renderCell,
    renderHeader: () => (
      <Text>
        <b>Significance</b>
      </Text>
    ),
    sortable: false,
  },
  {
    field: 'status',
    flex: 0.8,
    renderCell: renderStatus,
    renderHeader: () => (
      <Text>
        <b>Status</b>
      </Text>
    ),
    sortable: false,
  },
];

interface ReportsTableProps extends Omit<TableProps, 'columns'> {
  isLoading: boolean;
  tableOptions: TableOptions;
  paginationOptions: PaginationOptions;
  reports: Report[];
  totalReports: number;
  selectedIds: GridRowId[];
  setSelectedIds: (selectedRowIds: GridRowId[]) => void;
  allowSelection: boolean;
}

const dataToRows = (row: Report) => {
  const significance = getReportSignificanceText(row.analysisType, row.significantGenes).join(' ');
  return {
    ...row,
    significance,
    date: row.updatedAt ? formatDateString(row.updatedAt) : formatDateString(row.createdAt),
    status: REPORT_STATUS_NAMES[row.status as ReportStatus] ?? row.status,
  };
};

export const ReportsTable: React.FC<ReportsTableProps> = (props) => {
  const {
    allowSelection,
    reports,
    totalReports,
    setSelectedIds,
    selectedIds,
    isLoading,
    paginationOptions,
  } = props;
  const { setRowsPerPage, pageNumber, setPageNumber, rowsPerPage } = paginationOptions;

  return (
    <DataGridPro
      disableVirtualization
      disableSelectionOnClick
      loading={isLoading}
      columns={columns}
      rows={reports.map(dataToRows)}
      rowCount={totalReports}
      disableColumnMenu
      pagination
      page={pageNumber}
      pageSize={rowsPerPage}
      onPageSizeChange={(newPageSize: number) => setRowsPerPage(newPageSize)}
      onPageChange={(page: number) => setPageNumber(page)}
      paginationMode="server"
      rowsPerPageOptions={[250, 500, 1000]}
      checkboxSelection={allowSelection}
      onSelectionModelChange={(newSelection) => {
        setSelectedIds(newSelection);
      }}
      selectionModel={selectedIds}
      onRowClick={(rowData: GridRowParams) =>
        openReportInNewTab(Number(rowData.id), rowData.row.analysisType)
      }
      sx={{
        cursor: 'pointer',
      }}
      components={{
        NoRowsOverlay: () => (
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.default,
              // @rohittalwalkar - despite the slot name containing "overlay", it does not stack ontop
              // setting the z-index fixes that issue
              zIndex: 1,
            }}
          >
            <Text>No Reports</Text>
          </Box>
        ),
      }}
    />
  );
};
