import { withAuthenticationRequired } from 'react-oidc-context';

interface PageProps {
  requiresAuth: boolean;
  children?: React.ReactNode;
}

const AuthPage: React.FC<Omit<PageProps, 'requiresAuth'>> = withAuthenticationRequired(
  ({ children }) => <>{children}</>,
  // there's a typo in the docs, the function starts with an uppercase "o"
  // https://github.com/authts/react-oidc-context?tab=readme-ov-file#protect-a-route
  // https://github.com/authts/react-oidc-context/blob/4f2127bb1926099a19900e37a48a038b7018b454/src/withAuthenticationRequired.tsx#L14
  { OnRedirecting: () => <div>Not logged in, redirecting to login page...</div> }
);

export const Page: React.FC<PageProps> = (props) => {
  const { requiresAuth, children } = props;
  if (requiresAuth) {
    return <AuthPage>{children}</AuthPage>;
  }
  return <>{children}</>;
};
