/**
 * Must be a child of AuthProvider
 * Note: Currently tested via usage in App.test.tsx
 */
import { Text } from '@color/continuum';
import { useLocalStorageState } from '@color/lib';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { Dashboard } from 'components/Dashboard/Dashboard';
import { Login } from 'components/Login/Login';
import { MfaPage } from 'components/Mfa/MfaPage';
import { Reports } from 'components/Reports/Reports';
import { Variants } from 'components/Variants/Variants';
import { MFA_PAGE_URL } from 'lib/constants';
import { OidcProvider } from 'lib/types';

import { Nav } from '../Nav/Nav';

interface Props {
  oidcProvider: OidcProvider;
  setOidcProvider: (provider: OidcProvider) => void;
}

export const AppRoutes: React.FC<Props> = (props) => {
  const auth = useAuth();
  const flags = useFlags();
  const location = useLocation();
  const { oidcProvider, setOidcProvider } = props;
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [storedOidcProvider] = useLocalStorageState<OidcProvider | undefined>(
    'oidcProvider',
    undefined
  );

  // automatically login in if the user opens a new tab
  // based of code in the react-oidc-context docs:
  // https://github.com/authts/react-oidc-context?tab=readme-ov-file#automatic-sign-in
  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    if (
      storedOidcProvider &&
      oidcProvider &&
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin, oidcProvider, setOidcProvider, storedOidcProvider]);

  if (auth.activeNavigator === 'signinSilent') {
    return <Text variant="body1">Signing you in...</Text>;
  }
  if (auth.activeNavigator === 'signoutRedirect') {
    return <Text variant="body1">Signing you out...</Text>;
  }
  if (auth.isLoading) {
    return <Text variant="body1">Loading...</Text>;
  }
  if (auth.error) {
    return <Text variant="body1">Oops... {auth.error.message}</Text>;
  }
  if (!auth.isAuthenticated) {
    return (
      <Switch>
        <Login
          oidcProvider={oidcProvider}
          setOidcProvider={setOidcProvider}
          onLogin={auth.signinRedirect}
        />
      </Switch>
    );
  }

  const onMfaPage = location.pathname === MFA_PAGE_URL;

  return (
    <>
      {!onMfaPage && <Nav />}
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Route path="/mfa">
          <MfaPage />
        </Route>

        <Route path="/dashboard">
          <Dashboard />
        </Route>
        {!flags.hideVariantsList && (
          <Route path="/variants">
            <Variants />
          </Route>
        )}
        <Route path="/reports">
          <Reports />
        </Route>
      </Switch>
    </>
  );
};
