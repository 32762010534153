import { Menu, MenuItem, MuiButtonProps } from '@color/continuum';
import { Children, cloneElement, useState } from 'react';

export interface IDropdownMenuProps {
  DropdownButton: React.ReactElement<MuiButtonProps>;
  menuId: string;
  children: React.ReactElement | React.ReactElement[];
}

export const DropdownMenu: React.FC<IDropdownMenuProps> = (props) => {
  const { menuId, DropdownButton, children } = props;

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      {cloneElement(DropdownButton, {
        'aria-controls': menuId,
        'aria-haspopup': true,
        onClick: handleOpen,
      })}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        id={menuId}
        keepMounted
        onClose={handleClose}
        open={isMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionProps={{
          onExited: () => setAnchorEl(null),
        }}
        PaperProps={{
          sx: (theme) => ({
            borderRadius: 0,
            border: `1px solid ${theme.palette.divider}`,
            minWidth: anchorEl?.offsetWidth,
          }),
        }}
      >
        {Children.map(children, (menuItem: React.ReactElement) => (
          <MenuItem
            {...menuItem.props}
            onClick={() => {
              handleClose();
              menuItem.props.onClick?.();
            }}
          />
        ))}
      </Menu>
    </>
  );
};
