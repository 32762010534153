import { Box, Spacer, Stack, Text, Title } from '@color/continuum';
import { QRCodeSVG } from 'qrcode.react';

type MfaRegisterProps = {
  otpauth: string;
};

export const MfaRegister: React.FC<MfaRegisterProps> = (props) => {
  const { otpauth } = props;

  if (!otpauth) {
    return <Text>Loading...</Text>;
  }

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
      <Title>Register for Multi Factor Authentication</Title>
      <Text>
        Please open your Google Authenticator app and scan the QR code and then follow the
        instructions provided.
      </Text>
      <Spacer variant="medium" />
      {otpauth && (
        <Box display="flex" justifyContent="center">
          <QRCodeSVG value={otpauth} />
        </Box>
      )}
      <Spacer variant="medium" />
    </Stack>
  );
};
