import { AouDashboardCard, Image, Text } from '@color/continuum';

import dnaImage from 'assets/images/dna.svg';
import { useColorQuery } from 'lib/api';

interface ChallengedVariantSummaryCardProps {
  variantIds: number[];
  pendingChallenges: number;
}

export const ChallengedVariantSummaryCard: React.FC<ChallengedVariantSummaryCardProps> = (
  props
) => {
  const { pendingChallenges, variantIds } = props;
  const data = useColorQuery<{ numReportsOnHold: number }>('/dashboard/reports_on_hold', {
    searchParams: { variant_ids: variantIds.join(',') },
  });
  if (data.isLoading) {
    return <Text>...loading</Text>;
  }
  if (data.isError) {
    return <Text>Error: {data.error.errorMessage}</Text>;
  }
  if (!data.data) {
    return <Text>No data</Text>;
  }
  return (
    <AouDashboardCard
      title={`Resolve ${pendingChallenges} variants`}
      subtitle={`Blocking ${data.data.numReportsOnHold} reports`}
      icon={
        <Image
          src={dnaImage}
          alt="DNA"
          sx={{
            height: '72px',
          }}
        />
      }
      buttonLinkTo="/variants?filter=challenged"
      buttonContent="Resolve Variants"
      buttonVariant="color-primary"
    />
  );
};
