import { PageContainer, Spacer } from '@color/continuum';
import { Route, Switch } from 'react-router-dom';

import { Footer } from './Footer';
import { ReportDetails } from './ReportDetails/ReportDetails';
import { ReportsList } from './ReportsList';

export const Reports = () => (
  <PageContainer topBottomMarginVariant="xxxLarge" helmetTitle="Variants">
    {/* TODO: remove this extra spacer when the Appbar is migrated to shared Appbar component (https://getcolor.atlassian.net/browse/PC-162) */}
    <Spacer variant="xxLarge" />
    <Switch>
      <Route exact path="/reports">
        <ReportsList />
      </Route>
      <Route path="/reports/:id">
        <ReportDetails />
        <Footer />
      </Route>
    </Switch>
  </PageContainer>
);
