import { baseThemeV5, createTheme } from '@color/continuum';

export const rhpThemeV5 = createTheme(baseThemeV5, {
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          background: baseThemeV5.palette.background.lightBg,
        },
      },
    },
  },
});
