import { Grid, InternalLink, Spacer, Text, Title } from '@color/continuum';
import { isEmpty } from 'lodash';

import { AlertCard } from 'components/common/AlertCard';
import { UserInfo, useColorQuery } from 'lib/api';
import { ReportHold } from 'lib/types';
import { formatDateString } from 'lib/utils';

export const OnHoldCard: React.FC<ReportHold & { holdReason: string }> = (props) => {
  const { createdAt, byCvl, holdReason, variantNameAndIds } = props;
  const title = <Text>This report is on hold</Text>;

  const { isLoading, isError, error, data } = useColorQuery<UserInfo>(`/userinfo`);

  if (isError) {
    return <Text>{`${error}`}</Text>;
  }
  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  // @rohittalwalkar - useColorQuery might return "undefined"
  if (data === undefined) {
    return <Text>Loading...</Text>;
  }
  const formattedDate = formatDateString(createdAt);
  const displayHoldReason = isEmpty(variantNameAndIds);
  return (
    <AlertCard alertSeverity="warning" alertTitle={title}>
      <Grid>
        <Grid item>
          {byCvl !== null && data.cvl === byCvl && (
            <Title variant="headline3">
              Report was put on hold by your lab&apos;s user on {formattedDate}
            </Title>
          )}
          {byCvl !== null && data.cvl !== byCvl && (
            <Title variant="headline3">
              Report was put on hold by {byCvl} on {formattedDate}
            </Title>
          )}
          {byCvl === null && (
            <Title variant="headline3">{`Report was put on hold by RHP on ${formattedDate}`}</Title>
          )}
        </Grid>
        <Spacer variant="small" />
        <Grid item>
          {displayHoldReason && <Text variant="body1">Notes: {holdReason}</Text>}
          {displayHoldReason === false && (
            <>
              {/* TODO: rohittalwalkar - do not hardcode the hold reason */}
              <Text>Variants in report are discordant:</Text>
              {variantNameAndIds.map((nameAndId) => (
                <Text>
                  <InternalLink key={nameAndId.id} to={`/variants/${nameAndId.id}`}>
                    {nameAndId.name}
                  </InternalLink>
                </Text>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </AlertCard>
  );
};
