import { Button, Grid, Spacer, Text, Title } from '@color/continuum';
import { camelCase } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';

import { AlertCard } from 'components/common/AlertCard';
import { HarmonizeFields } from 'lib/types';

type Props = {
  // TODO PC-1592 make interpretationOwner and challengeType non-optional after backfill
  challengedBy?: string;
  interpretationOwner?: string;
  challengeType?: string;
  fields: string[];
  fieldValues: HarmonizeFields;
};

export const ResolveCard: React.FC<Props> = (props) => {
  const { challengedBy, interpretationOwner, challengeType, fields, fieldValues } = props;
  const { id: variantId } = useParams<{ id: string }>();
  const history = useHistory();
  const isAutomaticChallenge = challengeType && challengeType === 'AUTOMATIC';

  const challengedFields = fields.map((field) => (
    <li key={field}>
      <Text>
        <b>
          <i>All of Us</i> {`${field}: ${fieldValues[camelCase(field) as keyof HarmonizeFields]}`}
        </b>
      </Text>
    </li>
  ));

  return (
    <AlertCard
      alertSeverity="warning"
      alertTitle="Action required: Review new variant interpretation submission with the Variant Adjudication Committee at the next meeting"
    >
      <Grid container>
        <Grid item>
          <Title variant="headline3">
            <Text>{interpretationOwner} submitted a new variant interpretation </Text>
            <Text>Impacted reports have been automatically put on hold</Text>
          </Title>
        </Grid>
        <Spacer variant="small" />
        <Grid item>
          <Text variant="body1">
            {isAutomaticChallenge ? <i data-testid="italic-aou">All of Us</i> : challengedBy}{' '}
            requested that the Variant Adjudication Committee review the current:
          </Text>
          <ul>{challengedFields}</ul>
          <Text variant="body1">
            Until then, reports with this variant interpretation that haven&apos;t been signed out
            are on hold from all CVLs.
          </Text>
        </Grid>
        <Spacer variant="small" />

        <Grid item>
          <Button
            variant="color-primary"
            onClick={() => history.push(`/variants/${variantId}/resolve`)}
          >
            Resolve
          </Button>
        </Grid>
      </Grid>
    </AlertCard>
  );
};
