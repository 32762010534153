import { AouDashboardCard, Image } from '@color/continuum';
import pluralize from 'pluralize';
import { ReactNode } from 'react';

import dnaImage from 'assets/images/dna.svg';
import { Paths } from 'lib/constants';
import { formatDateString, getDiscordantFields } from 'lib/utils';

export interface VariantTaskCardProps {
  title: string;
  children?: ReactNode;
  variant: any;
  cvl?: string;
}

export const VariantTaskCard: React.FC<VariantTaskCardProps> = (props: VariantTaskCardProps) => {
  const { title, variant, cvl, children } = props;
  const icon = (
    <Image
      src={dnaImage}
      alt="DNA"
      sx={{
        height: '72px',
        width: '72px',
      }}
    />
  );
  const link = `/${Paths.variants}/${variant.id}`;
  const rhpFields = {
    classification: variant.rhpInterpretation.classification,
    disorder: variant.rhpInterpretation.disorder,
    lowPenetrance: variant.rhpInterpretation.lowPenetrance,
    gHgvs: variant.rhpInterpretation.gHgvs,
    cHgvs: variant.rhpInterpretation.cHgvs,
    pHgvs: variant.rhpInterpretation.pHgvs,
    transcript: variant.rhpInterpretation.transcript,
  };
  let cvlInterpretation = variant.cvlInterpretations[0];
  if (cvl) {
    cvlInterpretation = variant.cvlInterpretations.find((ci: any) => ci.cvl === cvl);
  }
  const differences = getDiscordantFields(rhpFields, cvlInterpretation);
  const subtitle = `${differences.length} discordant variant ${pluralize(
    'field',
    differences.length
  )}: ${differences.map((d) => d.caption).join(', ')}. Variant interpretation reported by ${
    cvlInterpretation.cvl
  } on ${formatDateString(cvlInterpretation.classificationDate)}`;

  return (
    <AouDashboardCard
      title={title}
      subtitle={subtitle}
      icon={icon}
      buttonLinkTo={link}
      buttonContent="View Details"
      buttonVariant="color-primary"
    >
      {children}
    </AouDashboardCard>
  );
};
