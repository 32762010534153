// @ts-nocheck
import { AouDashboardCard, Image, Spacer } from '@color/continuum';
import { useFlags } from 'launchdarkly-react-client-sdk';
import pluralize from 'pluralize';
import { Fragment } from 'react';

import clipboardImage from 'assets/images/clipboard.svg';
import { Paths } from 'lib/constants';
import { ReportTask } from 'lib/types';

export interface ReportTaskCardProps {
  task: ReportTask;
}

export const ReportTaskCard: React.FC<ReportTaskCardProps> = (props: ReportTaskCardProps) => {
  const { rhpAllowReSignOuts, useNewDashboardApi } = useFlags();

  const { task } = props;
  const icon = (
    <Image
      src={clipboardImage}
      alt="Clipboard icon"
      sx={{
        height: '72px',
        width: '72px',
      }}
    />
  );
  const reportTitleMap = {
    on_automatic_hold: {
      title: '1 report was automatically put on hold',
    },
    on_manual_hold: {
      title: '1 report was manually put on hold',
    },
  };
  const getSubtitle = (taskType: string, report: any) => {
    const analysisTypeString = report.analysisType === 'HDRV1' ? 'HDR' : 'PGx';

    // const informativeString = report.significantGenes ? 'informative' : 'uninformative';
    // TODO @rohittalwalkar - delete when we're able to deploy RHP's UI
    const informativeString = report.isInformative ? 'informative' : 'uninformative';

    return `${analysisTypeString} ${informativeString} report. Issue Details: ${report.holdReason}`;
  };

  if (task.reports) {
    if (useNewDashboardApi) {
      return <></>;
    }
    return (
      <>
        {task.reports.map((report, idx: number) => (
          <Fragment key={report.id}>
            {idx > 0 && <Spacer variant="small" />}
            <AouDashboardCard
              title={reportTitleMap[task.type].title}
              subtitle={getSubtitle(task.type, report)}
              icon={icon}
              buttonLinkTo={
                report.analysisType === 'HDRV1'
                  ? `/${Paths.reports}/${report.id}/variants`
                  : `/${Paths.reports}/${report.id}/alleles`
              }
              buttonContent="View Details"
              buttonVariant="color-primary"
            />
          </Fragment>
        ))}
      </>
    );
  }
  const reportString = pluralize('report', task.count);
  const cardPropFragments = {
    ready_for_sign_out: {
      title: 'ready to sign out',
      subtitle: rhpAllowReSignOuts ? `HDR ${reportString}` : `HDR informative ${reportString}`,
      link: `/${Paths.reports}#sign_out`,
    },
    ready_for_bulk_sign_out: {
      title: 'ready to bulk sign out',
      subtitle: `HDR uninformative and PGx ${reportString}`,
      link: `/${Paths.reports}#bulk_sign_out`,
    },
  };
  const title = `${task.count} ${reportString} ${cardPropFragments[task.type].title}`;
  return (
    <AouDashboardCard
      title={title}
      subtitle={cardPropFragments[task.type].subtitle}
      icon={icon}
      buttonLinkTo={cardPropFragments[task.type].link}
      buttonContent="View Details"
      buttonVariant="color-primary"
    />
  );
};
