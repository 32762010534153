import { TableBody, TableCell, TableRow, Text } from '@color/continuum';

import { formatDateString } from 'lib/utils';

type EventLog = {
  id: string;
  createdAt: string;
  eventType: string;
  metadata: any;
  cvl: string;
  notes: string;
};

interface ReporthHistoryTableBodyProps {
  events: EventLog[];
}
export const ReporthHistoryTableBody: React.FC<ReporthHistoryTableBodyProps> = (props) => {
  const { events } = props;

  return (
    <TableBody>
      {events.map((event) => {
        return (
          <TableRow key={event.id}>
            <TableCell>
              <Text>{formatDateString(event.createdAt)}</Text>
            </TableCell>
            <TableCell>
              <Text>{event.eventType}</Text>
            </TableCell>
            <TableCell>
              <Text>{event.notes}</Text>
            </TableCell>
            <TableCell>
              <Text>{event.cvl}</Text>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};
