import { Box, Container, InternalButtonLink, Spacer, Text } from '@color/continuum';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { capitalize, isEmpty } from 'lodash';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import { NavTabs } from 'components/common/NavTabs/NavTabs';
import { Header } from 'components/Reports/ReportDetails/Header';
import { VariantsTab } from 'components/Reports/ReportDetails/VariantsTab';
import { useColorQuery } from 'lib/api';
import { ClinicalAnalysisType, Report } from 'lib/types';
import { formatDateString } from 'lib/utils';

import { AllelesTab } from './AllelesTab';
import { NeedHrdfCard } from './NeedHrdfCard';
import { ReportHistoryTab } from './ReportHistoryTab';
import { ReportTab } from './ReportTab';

interface ReportParams {
  id: string;
}

const getNavTabs = (report: Report, url: string, rhpAllowReSignOuts: boolean) => {
  const navTabs = [];
  if (report.analysisType === ClinicalAnalysisType.Pgxv1) {
    navTabs.push({ label: 'Star Alleles', path: `${url}/alleles` });
  } else {
    navTabs.push({ label: 'Variants', path: `${url}/variants` });
  }
  navTabs.push({ label: 'Reports', path: `${url}/reports` });
  if (rhpAllowReSignOuts) {
    navTabs.push({ label: 'History', path: `${url}/history` });
  }
  return navTabs;
};

export const ReportDetails: React.FC = () => {
  const { path, url } = useRouteMatch();
  const { id: reportId } = useParams<ReportParams>();
  const { rhpAllowReSignOuts } = useFlags();

  const { isLoading, isError, error, data: report } = useColorQuery<Report>(`/reports/${reportId}`);

  if (isError) {
    return <Text>{`${error}`}</Text>;
  }
  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  // @rohittalwalkar - useColorQuery might return "undefined"
  if (report === undefined) {
    return <Text>Loading...</Text>;
  }

  const variantInterpretationChanges = Object.keys(report.variantsNeedingUpdatedHrdf).map(
    (variantId) => {
      const variantChanges = report.variantsNeedingUpdatedHrdf[variantId];
      const { unharmonizedFields } = variantChanges;
      return {
        variantId,
        variantName: variantChanges.variantName,
        changes: Object.keys(unharmonizedFields).map((caption) => {
          return {
            caption: capitalize(caption),
            oldValue: unharmonizedFields[caption].cvl,
            newValue: unharmonizedFields[caption].rhp,
          };
        }),
      };
    }
  );
  const needsUpdatedHrdf = !isEmpty(report.variantsNeedingUpdatedHrdf);

  return (
    <Container>
      <Box display="flex" justifyContent="left">
        {/* TODO @rohittalwalkar - extract to a common spot and update Variants as well */}
        <InternalButtonLink
          to="/reports"
          startIcon={<ArrowBackIcon />}
          sx={(theme) => ({
            background: theme.palette.common.white,
            color: theme.palette.common.black,
            '&:hover': {
              background: theme.palette.grey[300],
            },
            border: '1px solid',
            borderColor: theme.palette.common.black,
          })}
        >
          <Text bold>Back</Text>
        </InternalButtonLink>
      </Box>
      <Spacer variant="xxxLarge" />
      <Box paddingX={10}>
        <Header
          reportId={report.id.toString()}
          sampleId={report.sampleId}
          sex={report.sexAtBirth}
          age={report.age.toString()}
          biobankId={report.biobankId}
          testType={report.analysisType}
          revisionNumber={report.revisionNumber}
          significantGenes={report.significantGenes}
          status={report.status}
          state={report.state}
          holdReason={report.holdReason}
          holdInfo={report.holdInfo}
          needsUpdatedHrdf={needsUpdatedHrdf}
          revisionReason={report.revisionReason}
          revisions={report.revisions}
        />
        {needsUpdatedHrdf && (
          <NeedHrdfCard
            variantInterpretationChanges={variantInterpretationChanges}
            holdDate={formatDateString(report.holdInfo?.createdAt || '')}
          />
        )}
        <NavTabs tabs={getNavTabs(report, url, rhpAllowReSignOuts)} defaultRoute="" />
      </Box>
      <Box>
        <Switch>
          <Route path={`${path}/variants`}>
            <VariantsTab />
          </Route>
          <Route path={`${path}/alleles`}>
            <AllelesTab />
          </Route>
          <Route path={`${path}/reports`}>
            <Spacer variant="medium" />
            <ReportTab />
          </Route>
          {rhpAllowReSignOuts && (
            <Route path={`${path}/history`}>
              <ReportHistoryTab />
            </Route>
          )}
        </Switch>
      </Box>
    </Container>
  );
};
