import { ConfirmCancelDialog, Spacer, Text } from '@color/continuum';

interface Props {
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
}

export const SignoutDialog: React.FC<Props> = (props) => {
  const { onAccept, onCancel, isOpen } = props;

  return (
    <ConfirmCancelDialog
      dialogIsOpen={isOpen}
      title="You're about to sign this report out"
      onAccept={onAccept}
      onCancel={onCancel}
    >
      <Text>
        Ready to sign this report out? Before you go, make sure you double checked the information
        below.
      </Text>
      <Spacer variant="small" />
      <Text bold>Report ID</Text>
      <Text>The Report ID is correct</Text>
      <Spacer variant="small" />
      <Text bold>Report</Text>
      <Text>Report is correct and complete</Text>
    </ConfirmCancelDialog>
  );
};
