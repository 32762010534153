import { TaskType, VariantTask } from 'lib/types';

import { VariantTaskCard } from './VariantTaskCard';

export interface VariantTaskProps {
  task: VariantTask;
  type: TaskType;
  cvl?: string;
}

export const VacMemberTaskCard: React.FC<VariantTaskProps> = (props: VariantTaskProps) => {
  const { task } = props;
  const title = 'Variant adjudication committee - resolve variant interpretation discrepancy';
  return <VariantTaskCard title={title} variant={task.variant} />;
};
