import { Box, Grid, Text } from '@color/continuum';

const dataTableGridItemSx = {
  borderTop: '#d6d6d6 solid 1px', // TODO @devchakraborty: Add #d6d6d6 to theme
};

const dataTableGridItemBoxSx = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  minHeight: '56px',
};

interface DataTableRowProps {
  name: React.ReactNode;
  children?: React.ReactNode;
}

export const DataTableRow: React.FC<DataTableRowProps> = (props) => {
  const { name, children } = props;
  return (
    <>
      <Grid item xs={6} sx={dataTableGridItemSx}>
        <Box sx={dataTableGridItemBoxSx}>
          <Text variant="body2" bold>
            {name}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={6} sx={dataTableGridItemSx}>
        <Box sx={dataTableGridItemBoxSx}>{children}</Box>
      </Grid>
    </>
  );
};

/**
 * FIXME Break this into separate files. Color standard is one component per file.
 */
interface DataTableProps {
  children?: React.ReactNode;
}

export const DataTable: React.FC<DataTableProps> = (props) => {
  const { children } = props;
  return (
    <Grid
      container
      sx={{
        borderBottom: '#d6d6d6 solid 1px', // TODO @devchakraborty: Add #d6d6d6 to theme
      }}
    >
      {children}
    </Grid>
  );
};
