// TODO (ALLOFUS-1217): We should reuse our LD wrapper once it's available in
// @color/lib

import { isDefined } from '@color/lib';
import { LDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import { config } from 'config';
import { UserInfo, useColorQuery } from 'lib/api';

interface Props {
  children?: React.ReactNode;
}

const LaunchDarklyIdentityProvider: React.FC<Props> = ({ children }) => {
  const client = useLDClient();
  const { isAuthenticated } = useAuth();
  const userInfoQuery = useColorQuery<UserInfo>('/userinfo');

  /**
   * `useEffect` is often dangerous and unnecessary. This usage needs review by #ux-programs team.
   * See https://getcolor.atlassian.net/browse/UXTASK-1406
   */
  // nosemgrep:semgrep-rules.ui-useEffect
  useEffect(() => {
    if (client && isAuthenticated && userInfoQuery.isSuccess) {
      client.identify({
        key: `rhp-user:${userInfoQuery.data.id}`,
        custom: {
          cvl: userInfoQuery.data.cvl,
        },
      });
    } else if (client) {
      client.identify({ anonymous: true });
    }
  }, [client, isAuthenticated, userInfoQuery.isSuccess, userInfoQuery.data]);

  return <>{children}</>;
};

export const LaunchDarklyProvider: React.FC<Props> = ({ children }) => {
  if (!isDefined(config.LAUNCHDARKLY_CLIENT_ID)) {
    return <>{children}</>;
  }
  return (
    <LDProvider clientSideID={config.LAUNCHDARKLY_CLIENT_ID}>
      <LaunchDarklyIdentityProvider>{children}</LaunchDarklyIdentityProvider>
    </LDProvider>
  );
};
