import { CvlInterpretation, TaskType, VariantTask } from 'lib/types';
import { formatDateString } from 'lib/utils';

import { NextSteps, NextStepsType } from './NextSteps';
import { VariantTaskCard } from './VariantTaskCard';

export interface VariantTaskProps {
  task: VariantTask;
  type: TaskType;
  cvl?: string;
}

export const VariantScientistTaskCard: React.FC<VariantTaskProps> = (props: VariantTaskProps) => {
  const { task, type, cvl } = props;
  const title = `Review variant interpretation for ${task.variant.gene} ${task.variant.rhpInterpretation.cHgvs} / ${task.variant.rhpInterpretation.pHgvs}`;
  let nextSteps = <></>;
  let cvlInterpretation: CvlInterpretation | undefined = task.variant.cvlInterpretations[0];
  if (cvl) {
    cvlInterpretation = task.variant.cvlInterpretations.find((ci) => ci.cvl === cvl);
  }
  if (type === TaskType.UnaddressedAcceptance && cvlInterpretation) {
    const acceptanceDate = formatDateString(cvlInterpretation.unaddressedNewFileRequest.createdAt);
    nextSteps = <NextSteps type={NextStepsType.Accepted} date={acceptanceDate} />;
  }
  if (type === TaskType.UnresolvedChallenge && cvlInterpretation) {
    const challengeDate = formatDateString(cvlInterpretation.unresolvedChallenge.createdAt);
    nextSteps = <NextSteps type={NextStepsType.Challenged} date={challengeDate} />;
  }

  return (
    <VariantTaskCard title={title} variant={task.variant} cvl={cvl}>
      {nextSteps}
    </VariantTaskCard>
  );
};
