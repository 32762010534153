import { Route, Switch } from 'react-router-dom';

import { Page } from '../common/Page';
import { Variant } from './Variant';
import { ResolvePage } from './Variant/ResolvePage';
import { VariantsList } from './VariantsList';

export const Variants: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/variants">
        <Page requiresAuth>
          <VariantsList />
        </Page>
      </Route>
      <Route path="/variants/:id" exact>
        <Page requiresAuth>
          <Variant />
        </Page>
      </Route>
      <Route path="/variants/:id/resolve">
        <Page requiresAuth>
          <ResolvePage />
        </Page>
      </Route>
    </Switch>
  );
};
