import { LicenseInfo } from '@mui/x-license-pro';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { initializeSentry } from 'lib/monitoring/monitoring';

import { App } from './components/App/App';

initializeSentry();

const renderApp = () => {
  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    document.getElementById('root')
  );
};

LicenseInfo.setLicenseKey(
  'c5c0e5ed79838d14dee5fb67dc96fea4Tz01NzAzNSxFPTE3MDQzOTI2MzQ5ODYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

renderApp();
