import { QueryClientProvider } from 'react-query';

import { queryClient } from 'lib/api';

interface Props {
  children?: React.ReactNode;
}

export const ApiQueryClientProvider: React.FC<Props> = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
