import { Box, Container, DeprecatedBadge, Spacer, Text, Title } from '@color/continuum';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { Variant } from 'lib/types';

import { getVariantTitle } from '../util';

export interface VariantHeaderProps {
  variant: Variant;
}

export const VariantHeader: React.FC<VariantHeaderProps> = ({ variant }) => {
  return (
    <Box position="relative">
      <Container>
        <Spacer variant="large" />
        <b>
          {variant.classification !== 'Uninformative' && (
            <DeprecatedBadge
              severity="error"
              icon={<AssignmentIcon />}
              label={variant.classification}
            />
          )}
          {variant.classification === 'Uninformative' && (
            <DeprecatedBadge severity="success" icon={<AssignmentIcon />} label="Non-reportable" />
          )}
        </b>
        <Spacer variant="large" />
        <Title>{getVariantTitle(variant)}</Title>
        <Box display="flex" columnGap={4} alignItems="center">
          <Text>{variant.gHgvs}</Text>
          <Text>
            <b>Associated disorder(s): </b>
            {variant.disorder}
          </Text>
        </Box>
      </Container>
    </Box>
  );
};
