import { AppMuiProviders } from '@color/continuum';

import { rhpThemeV5 } from 'lib/theme';

interface Props {
  children?: React.ReactNode;
}

export const ThemeProvider: React.FC<Props> = ({ children }) => (
  <AppMuiProviders themeV5={rhpThemeV5}>{children}</AppMuiProviders>
);
