import { Environment } from '@color/lib';

import { AppConfig } from './types';

export const config: AppConfig = {
  API_ROOT: '/api/v1',
  ENVIRONMENT: Environment.PRODUCTION,
  LAUNCHDARKLY_CLIENT_ID: '63091c1548a87e125d68eb16',
  SENTRY_DSN: 'https://ae958ab41bf741f2859b1833e9133f52@o22410.ingest.sentry.io/6109068',
  USE_REFRESH_TOKENS: true,
  OIDC_PROVIDERS: {
    google: {
      authority: 'https://accounts.google.com',
      client_id: '873731912317-fn9btj416j53cm2v36gu5bsg6h43keeo.apps.googleusercontent.com',
      client_secret: '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', // Not an actual secret (SPA client)
    },
    microsoft: {
      authority: 'https://login.microsoftonline.com/common/v2.0',
      client_id: '8e98565d-0fd8-4c91-86e3-5480df1a628b',
      client_secret: null,
    },
    auth0: {
      authority: 'https://aou-rhp-prod.us.auth0.com',
      client_id: 'VVK6oNKuBCb2REtXuidT2eHUCe5n3ZIN',
      client_secret: null,
    },
  },
};
