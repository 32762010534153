import { Grid, Spacer, Text, Title } from '@color/continuum';

import { AlertCard } from 'components/common/AlertCard';
import { DisplayDifference } from 'lib/types';

type Props = {
  lab: string;
  differences: Array<DisplayDifference>;
};

export const OnHoldAcceptedCard: React.FC<Props> = (props) => {
  const { lab, differences } = props;
  // @rohittalwalkar - construct the text as a variable to keep it on a single line for the tests
  const title = (
    <Text>
      {lab}&apos;s decision: Accept <i>All of Us</i> variant interpretation
    </Text>
  );
  const listOfDifferences = differences.map((difference) => (
    <Text key={difference.caption}>
      <li>
        <b>
          <i>All of Us</i> {difference.caption}: {difference.aou}
        </b>
      </li>
    </Text>
  ));
  return (
    <AlertCard alertSeverity="warning" alertTitle={title}>
      <Grid container>
        <Grid item>
          <Title variant="headline3">Impacted reports have been automatically put on hold</Title>
        </Grid>
        <Spacer variant="small" />
        <Grid item>
          <Text variant="body1">
            Your lab chose to accept:<ul>{listOfDifferences}</ul>
          </Text>
          <Text variant="body1">
            You need to resubmit a revised health-related data file with updated variant
            interpretation.
          </Text>
        </Grid>
      </Grid>
    </AlertCard>
  );
};
