import { Box, DeprecatedBadge, Spacer } from '@color/continuum';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  HeaderReportInfo,
  Props as HeaderReportInfoProps,
} from 'components/Reports/ReportDetails/HeaderReportInfo';
import { RevisionNotification } from 'components/Reports/ReportDetails/RevisionNotification';
import { ReportHold, Revision, RevisionReason } from 'lib/types';

import { OnHoldCard } from './OnHoldCard';

type Props = HeaderReportInfoProps & {
  status: string | undefined;
  holdInfo?: ReportHold;
  holdReason?: string;
  reportId: string;
  state: string;
  needsUpdatedHrdf: boolean;
  revisionReason?: RevisionReason;
  revisions?: Revision[];
};

const ReportStatusChip: React.FC<{ reportStatus: string | undefined }> = (props) => {
  const { reportStatus } = props;
  return (
    <>
      {reportStatus === 'pending' && (
        <DeprecatedBadge severity="warning" icon={<AssignmentLateIcon />} label="On hold" />
      )}
      {reportStatus === 'ready_for_sign_out' && (
        <DeprecatedBadge severity="info" icon={<AssignmentIcon />} label="Ready for sign out" />
      )}
      {reportStatus === 'ready_for_bulk_sign_out' && (
        <DeprecatedBadge
          severity="info"
          icon={<AssignmentIcon />}
          label="Ready for bulk sign out"
        />
      )}
      {reportStatus === 'released' && (
        <DeprecatedBadge severity="neutral" icon={<CheckBoxIcon />} label="Signed out" />
      )}
    </>
  );
};

export const Header: React.FC<Props> = (props) => {
  const {
    sampleId,
    sex,
    age,
    biobankId,
    testType,
    significantGenes,
    revisionNumber,
    revisionReason,
    status,
    holdReason,
    holdInfo,
    reportId,
    needsUpdatedHrdf,
    revisions,
  } = props;
  const { rhpAllowReSignOuts } = useFlags();
  let isReportLatestRevision = false;
  let previousRevisions: Revision[] = [];
  let latestRevision: Revision | undefined;
  if (revisions && revisions.length > 0) {
    // reduce to latest revision based on revision.revision
    latestRevision = revisions.reduce((prev, current) =>
      prev.revision > current.revision ? prev : current
    );
    isReportLatestRevision = latestRevision.id.toString() === reportId;

    if (isReportLatestRevision) {
      previousRevisions = revisions.filter((revision) => revision.id.toString() !== reportId);
    }
  }

  return (
    <>
      <Box display="flex" justifyContent="left">
        <ReportStatusChip reportStatus={status} />
      </Box>
      <Spacer variant="large" />
      <HeaderReportInfo
        {...{
          reportId,
          sampleId,
          sex,
          age,
          biobankId,
          testType,
          significantGenes,
          revisionNumber,
        }}
      />
      <Spacer variant="large" />
      {status === 'pending' && holdInfo && holdReason && !needsUpdatedHrdf && (
        <>
          <OnHoldCard {...holdInfo} holdReason={holdReason} />
          <Spacer variant="large" />
        </>
      )}
      {isReportLatestRevision && revisionReason && revisions && (
        <>
          <RevisionNotification
            {...{ revisionReason, revisions: previousRevisions, latest: true }}
          />
          <Spacer variant="large" />
        </>
      )}
      {rhpAllowReSignOuts && !isReportLatestRevision && revisions && latestRevision && (
        <>
          <RevisionNotification {...{ revisions: [latestRevision], latest: false }} />
          <Spacer variant="large" />
        </>
      )}
    </>
  );
};
