import { Text } from '@color/continuum';
import { IErrorPayload } from '@color/lib';

interface IErrorPayloadExtended extends IErrorPayload {
  name?: string;
  errors?: string | string[];
}

export const DisplayError: React.FC<IErrorPayloadExtended> = ({
  message,
  errorMessage,
  name,
  errors,
}) => (
  <>
    {name && <Text>Name: {name}</Text>}
    {message && <Text>Message: {message}</Text>}
    {errorMessage && <Text>Error: {errorMessage}</Text>}
    {typeof errors === 'string' && <Text>Errors: {errors}</Text>}
    {errors && typeof errors !== 'string' && <Text>Errors: {errors.join(', ')}</Text>}
  </>
);
