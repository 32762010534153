import { Button, FormikSubmitButton, FormikTextField, Grid, Spacer } from '@color/continuum';

interface Props {
  onCancel: () => void;
}

export const OnHoldDialogFields: React.FC<Props> = (props) => {
  const { onCancel } = props;
  return (
    <>
      <FormikTextField
        label="What errors or issues do you see on this report?"
        hiddenLabel={false}
        name="holdReason"
        required
        requiredIndicatorIsEnabled
        multiline
        type="text"
      />
      <Spacer variant="medium" />
      <Grid container justifyContent="space-between">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <FormikSubmitButton isLoading={false} sx={{ marginLeft: 2 }}>
          Submit
        </FormikSubmitButton>
      </Grid>
    </>
  );
};
//
