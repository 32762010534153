import {
  Box,
  Container,
  InternalButtonLink,
  MuiAppBar,
  PageContainer,
  Spacer,
  Tab,
  TabPanel,
  Tabs,
  Text,
} from '@color/continuum';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { UserInfo, useColorQuery } from 'lib/api';
import { CAN_MANAGE_REPORTS } from 'lib/constants';
import { Variant as VariantData } from 'lib/types';

import { getVariantTitle } from '../util';
import { ShowAlertCard } from './ShowAlertCard';
import { VariantDetails } from './VariantDetails';
import { VariantHeader } from './VariantHeader';
import { VariantReports } from './VariantReports';

interface VariantParams {
  id: string;
}

export const Variant: React.FC = () => {
  const { id: variantId } = useParams<VariantParams>();
  const {
    isLoading,
    isError,
    error,
    data: variant,
  } = useColorQuery<VariantData>(`/variants/${variantId}`);
  const {
    isLoading: userIsLoading,
    isError: userIsError,
    error: userError,
    data: userInfo,
  } = useColorQuery<UserInfo>(`/userinfo`);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const flags = useFlags();

  if (isError || userIsError) {
    return <Text>{`${error} ${userError}`}</Text>;
  }
  if (isLoading || userIsLoading) {
    return <Text>Loading...</Text>;
  }

  // @rohittalwalkar - useColorQuery might return "undefined"
  if (variant === undefined || userInfo === undefined) {
    return <Text>Loading...</Text>;
  }

  const onTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setCurrentTab(newTab);
  };
  return (
    <PageContainer helmetTitle={getVariantTitle(variant)}>
      {/* TODO: remove this extra spacer when the Appbar is migrated to shared Appbar component (https://getcolor.atlassian.net/browse/PC-162) */}
      <Spacer variant="xxLarge" />
      {!flags.hideVariantsList && (
        <Container>
          <Box height="48px">
            <InternalButtonLink
              to="/variants"
              startIcon={<ArrowBackIcon />}
              sx={(theme) => ({
                background: theme.palette.common.white,
                color: theme.palette.common.black,
                '&:hover': {
                  background: theme.palette.grey[300],
                },
                border: '1px solid',
                borderColor: theme.palette.common.black,
              })}
            >
              <Text bold>Back to Variants</Text>
            </InternalButtonLink>
          </Box>
        </Container>
      )}
      <VariantHeader variant={variant} />
      <Spacer variant="medium" />
      <ShowAlertCard
        userCvl={userInfo.cvl}
        variant={variant}
        userPermissions={userInfo.permissions}
      />
      <Spacer variant="medium" />
      <Container>
        <MuiAppBar position="static" elevation={0}>
          <Tabs value={currentTab} onChange={onTabChange} aria-label={`${variant} Variant Tabs`}>
            <Tab
              label="Variant File"
              id="variantTab-details"
              aria-controls="variantTabPanel-details"
            />

            <Tab label="Reports" id="variantTab-reports" aria-controls="variantTabPanel-reports" />
          </Tabs>
        </MuiAppBar>
        <Spacer variant="xxxLarge" />
        {currentTab === 0 && (
          <TabPanel id="variantTabPanel-details" aria-labelledby="variantTab-details">
            <VariantDetails variant={variant} cvl={userInfo.cvl} />
          </TabPanel>
        )}
        {currentTab === 1 && (
          <TabPanel id="variantTabpanel-reports" aria-labelledby="variantTab-reports">
            {/* TODO @devchakraborty: Replace with real reports */}
            <VariantReports
              variantId={variant.id}
              enableLinks={userInfo.permissions.includes(CAN_MANAGE_REPORTS)}
            />
          </TabPanel>
        )}
      </Container>
    </PageContainer>
  );
};
