import { ConfirmCancelDialog, Text } from '@color/continuum';

interface Props {
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
}

export const RemoveHoldDialog: React.FC<Props> = (props) => {
  const { onAccept, onCancel, isOpen } = props;

  return (
    <ConfirmCancelDialog
      dialogIsOpen={isOpen}
      title="Remove hold"
      onAccept={onAccept}
      onCancel={onCancel}
    >
      <Text>
        You are about to remove the hold for this report. Once you click “Confirm,” this report will
        return to its original status.
      </Text>
    </ConfirmCancelDialog>
  );
};
