import { Column, Table, TableBody, TableCell, TableRow, Text } from '@color/continuum';
import WarningIcon from '@mui/icons-material/Warning';
import { Theme } from '@mui/material';

import { AouInterpretation, CvlInterpretation, Variant } from 'lib/types';
import { EMPTY_VALUE, formatDateString, getCvlData, isFieldDiscordant } from 'lib/utils';

/**
 * FIXME Break this into separate files. Color standard is one component per file.
 */

const columns: Column[] = [
  {
    id: 'variant_field',
    name: 'Variant field',
    isSortable: false,
  },
  {
    id: 'all_of_us',
    name: 'All of Us',
    isSortable: false,
  },
  {
    id: 'baylor',
    name: 'Baylor',
    isSortable: false,
  },
  {
    id: 'color',
    name: 'Color',
    isSortable: false,
  },
  {
    id: 'university_of_washington',
    name: 'University of Washington ',
    isSortable: false,
  },
];

type RenderableInterpretationDataKey = keyof Omit<
  CvlInterpretation,
  'unresolvedChallenge' | 'unaddressedNewFileRequest'
>;

type DetailRowProps = {
  aouData: AouInterpretation;
  cvlData: CvlInterpretation[];
  caption: string;
  dataKey: RenderableInterpretationDataKey;
  isReportable: boolean;
};

const blackTextSx = (theme: Theme) => ({
  color: theme.palette.common.black,
});

const redTextSx = (theme: Theme) => ({
  color: theme.palette.error.main,
});

const redWarningIconSx = (theme: Theme) => ({
  color: theme.palette.error.main,
});

const isAnyFieldDiscordant = (
  aouData: AouInterpretation,
  cvlInterpretations: CvlInterpretation[],
  field: keyof CvlInterpretation,
  isReportable: boolean
) => {
  return (
    cvlInterpretations.filter((interpretation) =>
      isFieldDiscordant(aouData, interpretation, field, isReportable)
    ).length >= 1
  );
};

/**
 * FIXME Break this into separate files. Color standard is one component per file.
 */
const DetailRow: React.FC<DetailRowProps> = (props) => {
  const { aouData, cvlData, dataKey, caption, isReportable } = props;
  return (
    <TableRow>
      <TableCell>
        <Text sx={blackTextSx}>{caption}</Text>
        {isAnyFieldDiscordant(aouData, cvlData, dataKey, isReportable) && (
          <Text sx={redTextSx}>Discordant</Text>
        )}
      </TableCell>
      <TableCell>
        <Text sx={blackTextSx}>{aouData[dataKey as keyof AouInterpretation] || EMPTY_VALUE}</Text>
      </TableCell>
      {cvlData.map((interpretation) => (
        <TableCell key={interpretation.cvl}>
          <Text sx={blackTextSx}>
            {isFieldDiscordant(aouData, interpretation, dataKey, isReportable) && (
              <WarningIcon sx={redWarningIconSx} />
            )}
            {interpretation[dataKey] || EMPTY_VALUE}
          </Text>
        </TableCell>
      ))}
    </TableRow>
  );
};

/**
 * FIXME Break this into separate files. Color standard is one component per file.
 */
interface Props {
  variant: Variant;
}

export const VariantDetailTable: React.FC<Props> = (props) => {
  const { variant } = props;
  const rowData = [
    { caption: 'Date variant entered RHP', key: 'createdAt' },
    { caption: 'Classification', key: 'classification' },
    { caption: 'Associated disorder', key: 'disorder' },
    { caption: 'Transcript', key: 'transcript' },
    { caption: 'cHGVS nomenclature', key: 'cHgvs' },
    { caption: 'pHGVS nomenclature', key: 'pHgvs' },
    { caption: 'gHGVS nomenclature', key: 'gHgvs' },
    { caption: 'Low Penetrance Flag', key: 'lowPenetrance' },
  ] as Array<{ caption: string; key: RenderableInterpretationDataKey }>;

  const aouData = {
    classification: variant.classification,
    cHgvs: variant.cHgvs,
    pHgvs: variant.pHgvs,
    gHgvs: variant.gHgvs,
    transcript: variant.transcript,
    lowPenetrance: variant.lowPenetrance,
    disorder: variant.disorder,
    createdAt: formatDateString(variant.createdAt),
  };

  const cvlData = ['BCM', 'CO', 'UW'].map((cvl) => getCvlData(cvl, variant.cvlInterpretations));

  return (
    <Table
      columns={columns}
      isLoading={false}
      tableOptions={{ sortDirections: {}, setSortDirections: () => {} }}
    >
      <TableBody>
        {rowData.map((row) => (
          <DetailRow
            aouData={aouData}
            cvlData={cvlData}
            caption={row.caption}
            dataKey={row.key}
            isReportable={variant.isReportable}
            key={row.key}
          />
        ))}
      </TableBody>
    </Table>
  );
};
