import {
  Box,
  Button,
  Container,
  Divider,
  FormikSubmitButton,
  Spacer,
  Text,
  Title,
} from '@color/continuum';
import { Form, Formik, FormikHelpers } from 'formik';
import { camelCase } from 'lodash';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { HGVS_FIELDS, REQUIRED_VAC_FIELDS, VAC_FIELDS } from 'lib/constants';
import { HarmonizeFields, Variant } from 'lib/types';

import { getCvlData, isFieldDiscordant } from '../../../lib/utils';
import { ResolveFormItems, isSingleOrGroupedHgvs } from './ResolveFormItems';

const getFieldOrder = (field: string) => {
  if (field === 'classification') {
    return 1;
  }
  if (field === 'disorder') {
    return 2;
  }
  if (field === 'lowPenetrance') {
    return 3;
  }
  if (isSingleOrGroupedHgvs(field)) {
    return 4;
  }
  // Transcript is always last
  return 5;
};

interface Props {
  variant: Variant;
  variantTitle: string;
  fieldsToResolve: string[];
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
}
export const ResolveForm: React.FC<Props> = (props) => {
  const history = useHistory();
  const { variant, variantTitle, onSubmit } = props;
  const nomenclatureFields = VAC_FIELDS.filter((field) => HGVS_FIELDS.has(field));
  const groupedFields = VAC_FIELDS.filter((field) => !HGVS_FIELDS.has(field));
  if (nomenclatureFields.length > 0) {
    groupedFields.push(nomenclatureFields.join(',') as (typeof VAC_FIELDS)[number]);
  }
  groupedFields.sort((a, b) => getFieldOrder(a) - getFieldOrder(b));

  const validationSchema:
    | {
        [key in (typeof VAC_FIELDS)[number]]?: Yup.StringSchema<string | undefined>;
      }
    | {
        [key in (typeof REQUIRED_VAC_FIELDS)[number]]: Yup.StringSchema<string | undefined>;
      } = {};
  const initialValues: {
    [key in (typeof VAC_FIELDS)[number]]?: string;
  } = {};
  // TODO PC-1592 make interpretationOwner and challengeType non-optional after backfill
  let cvl = variant.challengeInfo.fromCvl;
  if (variant.challengeInfo.challengeType && variant.challengeInfo.interpretationOwner) {
    cvl = variant.challengeInfo.interpretationOwner;
  }
  const cvlData = getCvlData(cvl, variant.cvlInterpretations);
  REQUIRED_VAC_FIELDS.forEach((field) => {
    validationSchema[field] = Yup.string().required('Required');
  });
  VAC_FIELDS.forEach((field) => {
    const isDiscordant = isFieldDiscordant(
      variant,
      cvlData,
      camelCase(field) as keyof HarmonizeFields,
      variant.isReportable
    );
    initialValues[field] = isDiscordant ? '' : cvlData[camelCase(field) as keyof HarmonizeFields]!;
  });
  return (
    <Box maxWidth="660px" alignContent="center">
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Title>Resolve variant interpretation for variant {variantTitle}</Title>
            <Spacer variant="medium" />
            <Text>
              Ensure that these resolved interpretations have been discussed and approved by the
              Variant Adjudication Committee.
            </Text>
            <Spacer variant="medium" />
            {groupedFields.map((field, index) => (
              <Container key={field}>
                <ResolveFormItems
                  variant={variant}
                  challengeDate={variant.challengeInfo.challengeDate}
                  cvl={cvl}
                  field={field}
                  position={index + 1}
                />
                <Spacer variant="xxLarge" />
                {index < groupedFields.length && (
                  <>
                    <Divider aria-hidden="true" />
                    <Spacer variant="xxLarge" />
                  </>
                )}
              </Container>
            ))}
            <Spacer variant="small" />
            <FormikSubmitButton isLoading={isSubmitting}>Submit</FormikSubmitButton>
            <Button
              variant="color-secondary"
              onClick={() => history.push(`/variants/${variant.id}`)}
            >
              Cancel
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
