import { ConfirmCancelDialog, Text } from '@color/continuum';

interface Props {
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
  numberOfReports: number;
}

export const BulkSignoutDialog: React.FC<Props> = (props) => {
  const { onAccept, onCancel, isOpen, numberOfReports } = props;

  return (
    <ConfirmCancelDialog
      dialogIsOpen={isOpen}
      title="You're about to sign out these reports"
      onAccept={onAccept}
      onCancel={onCancel}
    >
      <Text>
        You have selected {numberOfReports} reports. Are you sure you want to sign out these
        reports?
      </Text>
    </ConfirmCancelDialog>
  );
};
