import { Box, Button, Spacer, Text, Title } from '@color/continuum';
import { ButtonGroup } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useColorQuery } from 'lib/api';

enum Languages {
  ENGLISH = 'English',
  SPANISH = 'Spanish',
}
export const ReportTab: React.FC = () => {
  // TODO @rohittalwalkar - change to be a controlled component so that selection
  // and persist even when the user changes tabs
  const [selectedLanguage, setSelectedLanguage] = useState<Languages>(Languages.ENGLISH);
  const { id: reportId } = useParams<{ id: string }>();
  const { isLoading, isError, error, data } = useColorQuery<{ en: string; es: string }>(
    `/reports/${reportId}/pdf_urls`
  );
  if (isError) {
    return <Text>{`${error}`}</Text>;
  }
  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  // @rohittalwalkar - useColorQuery might return "undefined"
  if (data === undefined) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Title>Report</Title>
        <ButtonGroup variant="contained">
          {Object.values(Languages).map((language) => (
            <Button
              key={language}
              variant={selectedLanguage === language ? 'color-primary' : 'color-secondary'}
              aria-pressed={selectedLanguage === language}
              onClick={() => setSelectedLanguage(language)}
            >
              {language}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <Spacer variant="medium" />
      <Box
        sx={{
          height: '600px', // @rohittalwalkar - add some height so the page scrolls
          border: '1px solid black',
        }}
      >
        <Spacer variant="xSmall" />
        {selectedLanguage === Languages.ENGLISH && (
          <>
            <Title>English PDF</Title>
            <iframe width="100%" height="100%" title="English PDF" src={data.en} />
          </>
        )}
        {selectedLanguage === Languages.SPANISH && (
          <>
            <Title>Spanish PDF</Title>
            <iframe width="100%" height="100%" title="Spanish PDF" src={data.es} />
          </>
        )}
      </Box>
    </Box>
  );
};
