import { useState } from 'react';

import { CAN_MANAGE_REPORTS, VAC_PERMISSION_CODENAME } from 'lib/constants';
import { Variant } from 'lib/types';
import { getCvlData, getDiscordantFields, noFieldsHaveValues } from 'lib/utils';

import { AcceptChallengeCard } from './AcceptChallengeCard';
import { OnHoldAcceptedCard } from './OnHoldAcceptedCard';
import { OnHoldChallengedCard } from './OnHoldChallengedCard';
import { ResolveCard } from './ResolveCard';

interface Props {
  userPermissions?: string[];
  userCvl: string;
  variant: Variant;
}

export const ShowAlertCard: React.FC<Props> = (props) => {
  const { userCvl, variant, userPermissions } = props;
  const cvlData = getCvlData(userCvl, variant.cvlInterpretations, '');
  const hasCvlInterpretation = !noFieldsHaveValues(cvlData);
  const rhpFields = {
    classification: variant.classification,
    disorder: variant.disorder,
    lowPenetrance: variant.lowPenetrance,
    gHgvs: variant.gHgvs,
    cHgvs: variant.cHgvs,
    pHgvs: variant.pHgvs,
    transcript: variant.transcript,
  };
  const [showOnHoldAcceptCard, setShowOnHoldAcceptCard] = useState(false);
  const [showOnHoldChallengeCard, setShowOnHoldChallengeCard] = useState(false);

  const differences = getDiscordantFields(rhpFields, cvlData);

  const userCanResolveVariantConflict =
    userPermissions && userPermissions.includes(VAC_PERMISSION_CODENAME);

  const isReporter = userPermissions && userPermissions.includes(CAN_MANAGE_REPORTS);

  const hasPendingChallenge =
    variant.challengeInfo !== null && variant.challengeInfo.fields.length >= 0;
  const showResolveCard = userCanResolveVariantConflict && hasPendingChallenge;

  let interpretationOwner;
  let challengedBy;
  if (showResolveCard) {
    // TODO PC-1592 Remove use of fromCvl after backfill
    interpretationOwner = variant.challengeInfo.fromCvl;
    challengedBy = variant.challengeInfo.fromCvl;
    if (variant.challengeInfo.challengeType) {
      interpretationOwner = variant.challengeInfo.interpretationOwner;
      challengedBy = variant.challengeInfo.challengedBy;
    }
  }
  const latestNewFileRequestForCvl = variant.latestNewFileRequests.find(
    (newFileRequest) => newFileRequest.cvl === userCvl
  );
  const activeCvlInterpretation = variant.cvlInterpretations.find(
    (cvlInterpretation) => cvlInterpretation.cvl === userCvl
  );

  let hasPendingFileRequest = false;

  if (latestNewFileRequestForCvl?.createdAt && activeCvlInterpretation?.createdAt) {
    const newFileRequestCreatedAt = Date.parse(latestNewFileRequestForCvl.createdAt);
    const cvlInterpretationCreatedAt = Date.parse(activeCvlInterpretation.createdAt);
    hasPendingFileRequest = newFileRequestCreatedAt > cvlInterpretationCreatedAt;
  }

  // TODO @rohittalwalkar - also check to make sure a NewFileRequest
  // for the report.health_result.revision_number
  const showAcceptChallengeCard =
    !hasPendingChallenge &&
    !hasPendingFileRequest &&
    showOnHoldAcceptCard === false &&
    showOnHoldChallengeCard === false &&
    showResolveCard === false &&
    variant.isDiscordant &&
    hasCvlInterpretation &&
    differences.length > 0 &&
    isReporter;
  return (
    <>
      {showAcceptChallengeCard && (
        <AcceptChallengeCard
          differences={differences}
          setShowOnHoldAccept={setShowOnHoldAcceptCard}
          setShowOnHoldChallenge={setShowOnHoldChallengeCard}
          variantId={variant.id}
        />
      )}
      {showOnHoldChallengeCard && <OnHoldChallengedCard lab={userCvl} differences={differences} />}
      {showOnHoldAcceptCard && <OnHoldAcceptedCard lab={userCvl} differences={differences} />}
      {showResolveCard && (
        <ResolveCard
          interpretationOwner={interpretationOwner}
          challengedBy={challengedBy}
          challengeType={variant.challengeInfo.challengeType}
          fields={variant.challengeInfo.fields}
          fieldValues={rhpFields}
        />
      )}
      {/* TODO @rohittalwalkar add challenge on hold component */}
      {showOnHoldChallengeCard && <div />}
    </>
  );
};
