import { AouDashboardCard, Image, Text } from '@color/continuum';

import dnaImage from 'assets/images/dna.svg';
import { useColorQuery } from 'lib/api';

export const AwaitingHrdfSummaryCard: React.FC = () => {
  const data = useColorQuery<{ numReportsOnHold: number; numOfHrdfRequests: number }>(
    '/dashboard/awaiting_hrdf'
  );
  if (data.isLoading) {
    return <Text>...loading</Text>;
  }
  if (data.isError) {
    return <Text>Error: {data.error.errorMessage}</Text>;
  }
  if (!data.data) {
    return <Text>No data</Text>;
  }
  if (data.data.numOfHrdfRequests === 0) {
    return <></>;
  }
  return (
    <AouDashboardCard
      title={`Resubmit ${data.data.numOfHrdfRequests} HRDFs`}
      subtitle={`Blocking ${data.data.numReportsOnHold} reports`}
      icon={
        <Image
          src={dnaImage}
          alt="DNA"
          sx={{
            height: '72px',
          }}
        />
      }
      buttonLinkTo="/reports#awaiting_hrdf"
      buttonContent="View Details"
      buttonVariant="color-primary"
    />
  );
};
