export type JsonObject =
  | { [key: string]: JsonObject }
  | JsonObject[]
  | string
  | number
  | boolean
  | null;

export interface CvlInfo {
  fullName: string;
  slug: string;
}

export interface StatusInfo {
  fullName: string;
  slug: string;
}

export interface ClassificationInfo {
  displayName: string;
  internalName: string;
}

// Match the strings from ReportState(Enum)
export enum ReportStatus {
  AutomaticHold = 'automatic_hold',
  ManualHold = 'manual_hold',
  ReadyForSignout = 'ready_for_sign_out',
  ReadyForBulkSignout = 'ready_for_bulk_sign_out',
  Pending = 'pending',
  Released = 'released',
}

export const REPORT_STATUS_NAMES = {
  [ReportStatus.AutomaticHold]: 'Automatic Hold',
  [ReportStatus.ManualHold]: 'Manual Hold',
  [ReportStatus.ReadyForSignout]: 'Ready for sign out',
  [ReportStatus.ReadyForBulkSignout]: 'Ready for bulk sign out',
  [ReportStatus.Pending]: 'Pending',
  [ReportStatus.Released]: 'Released',
};

// TODO @devchakraborty: Update this type once it's implemented in the backend - made
// this up for now just using design mocks
export interface VariantReport {
  id: number;
  lastUpdated: string;
  sampleId: string;
  sex: string;
  age: number;
  cvl: CvlInfo;
  state: string;
  status: ReportStatus;
}

export type SignificantGene = {
  gene: string;
  classification: string;
};

interface NameAndId {
  name: string;
  id: number;
}
export interface ReportHold {
  createdAt: string;
  byCvl: string | null;
  variantNameAndIds: NameAndId[];
}

interface InterpretationChange {
  caption: string;
  oldValue: string;
  newValue: string;
}
export interface VariantInterpretationChanges {
  variantId: string;
  variantName: string;
  changes: InterpretationChange[];
}

interface VariantUpdatedHrdfApiItem {
  variantName: string;
  unharmonizedFields: Record<string, { cvl: string; rhp: string }>;
}

export type Revision = {
  revision: number;
  id: number;
};

/**
 * Example API chuck of VariantUpdatedHrdfApiItem:
 * variants_needing_updated_hrdf": {
    "236": {
      "variant_name": "BRCA1 / c.1187G>A / p.A1B",
      "unharmonized_fields": {
        "classification": {
          "cvl": "Pathogenic",
          "rhp": "Likely Pathogenic"
        },
        "transcript": {
          "cvl": "NM_001613",
          "rhp": "NM_000038"
        }
      }
    }
  },
 */
export type VariantsNeedingUpdatedHrdf = Record<string, VariantUpdatedHrdfApiItem>;

export enum RevisionReason {
  Upgrade = 'upgrade',
  Downgrade = 'downgrade',
}

export interface Report {
  id: number;
  createdAt: string;
  updatedAt?: string; // TODO @rohittalwalkar - verify updated is empty until an update happens
  state: string; // TODO @rohittalwalkar - perhaps replace with enum?
  status: string | undefined;
  biobankId: string;
  sampleId: string;
  analysisType: string; // TODO @rohittalwalkar - perhaps replace with enum?
  revisionNumber: number;
  previousRevisionLink: string | null;
  pdfLocation?: string;
  signedOutById?: string;
  signedOutAt?: string;
  significantGenes: SignificantGene[] | null;
  holdInfo?: ReportHold;
  holdReason?: string;
  cvl: string;
  age: number;
  sexAtBirth: string;
  variantsNeedingUpdatedHrdf: VariantsNeedingUpdatedHrdf;
  hasUnresolvedChallenge: boolean;
  hasUnaddressedFileRequest: boolean;
  revisionReason?: RevisionReason;
  revisions?: Revision[];
}

// TODO @devchakraborty: This is used only for comments; we should roll this into a
// general "user" type, ideally independent of the Auth0 user (more like a frontend
// analogue of RhpUser)
interface User {
  displayName: string;
  pictureUrl?: string;
}

export interface Comment {
  id: number;
  createdAt: string;
  user: User;
  text: string;
}

export enum TaskType {
  Discordance = 'discordance',
  UnresolvedChallenge = 'unresolvedChallenge',
  UnaddressedAcceptance = 'unaddressedAcceptance',
}

export enum InterpretationStatus {
  UNHARMONIZED = 'UNHARMONIZED',
  DISCORDANT = 'DISCORDANT',
  HARMONIZED = 'HARMONIZED',
}

export enum RhpClassification {
  Pathogenic = 'Pathogenic',
  LikelyPathogenic = 'LikelyPathogenic',
  RiskAlelle = 'RiskAlelle',
  Uninformative = 'Uninformative',
}

export enum ClinicalValidationLab {
  Bcm,
  Co,
  Uw,
}

export enum CvlClassification {
  Pathogenic,
  LikelyPathogenic,
  RiskAlelle,
  UncertainSignificance,
  LikelyBenign,
  Benign,
  NotAssigned,
}

export enum NewFileRequestReason {
  NewVariantClassification = 'new variant classification',
}

export enum ClinicalAnalysisType {
  Pgxv1 = 'PGxV1',
  Hdrv1 = 'HDRV1',
}

export interface RhpInterpretation {
  id: number;
  classification: RhpClassification;
  disorder: string;
  lowPenetrance: boolean;
  gHgvs: string;
  cHgvs: string;
  pHgvs: string;
  transcript: string;
}

export interface Challenge {
  createdAt: string;
  updatedAt: string;
  challengedById: number;
  resolvedById: number;
  resolvedAt: string;
}

export interface NewFileRequest {
  id: number;
  createdAt: string;
  updatedAt: string;
  cvl: ClinicalValidationLab;
  requestReason: NewFileRequestReason;
  requestReasonFree: string;
  clinicalAnalysisType: ClinicalAnalysisType;
  revisionNumber: number;
  s3Key: string;
}

export interface NewFileRequestInfo {
  id: number;
  createdAt: string;
  cvl: string;
  requestReason: string;
}
export interface CvlInterpretation {
  id: number;
  status: InterpretationStatus;
  cvl: string;
  classification: string;
  lowPenetrance: string;
  cHgvs: string;
  pHgvs: string;
  gHgvs: string;
  transcript: string;
  disorder: string;
  variantDetailsLink: string;
  classificationWriteUp: string;
  classificationDate: string;
  createdAt: string;
  unresolvedChallenge: Challenge;
  unaddressedNewFileRequest: NewFileRequest;
}

export type AouInterpretation = Omit<
  CvlInterpretation,
  | 'id'
  | 'status'
  | 'cvl'
  | 'variantDetailsLink'
  | 'classificationWriteUp'
  | 'classificationDate'
  | 'unresolvedChallenge'
  | 'unaddressedNewFileRequest'
>;

export type HarmonizeFields = Omit<AouInterpretation, 'createdAt'>;

export type DisplayDifference = {
  aou: string | boolean;
  lab: string;
  caption: string;
};

export interface ChallengeInfo {
  fromCvl: string;
  fields: string[];
  challengeDate: string;
  // TODO PC-1592 make interpretationOwner and challengeType non-optional after backfill
  interpretationOwner?: string;
  challengedBy?: string;
  challengeType?: string;
}

export interface Variant {
  id: number;
  gene: string;
  chromosome: string;
  position: number;
  ref: string;
  alt: string;
  referenceGenomeVersion: string;
  cHgvs: string;
  gHgvs: string;
  pHgvs: string;
  lastClassificationDate?: string;
  classification: string;
  disorder: string;
  isReportable: boolean;
  status?: string;
  transcript: string;
  clinvarUrl: string;
  cvlInterpretations: CvlInterpretation[];
  rhpInterpretation: RhpInterpretation;
  lowPenetrance: string;
  createdAt: string;
  updatedAt: string;
  isDiscordant: boolean;
  challengeInfo: ChallengeInfo;
  zygosity?: string;
  cvls: Array<{ cvl: string }>;
  cvlsWithPendingFileRequests: Array<{ cvl: string }>;
  interpretationStatus: InterpretationStatus;
  latestNewFileRequests: NewFileRequestInfo[];
  numReportsOnHold: number;
}

export interface VariantTask {
  type: any;
  variant: Variant;
  isChallenged: boolean;
  isAwaitingInterpretation: boolean;
}

export interface ReportTask {
  type: TaskType;
  reports?: Report[];
  count?: number;
}

export interface Allele {
  id: number;
  gene: string;
  diplotype: string;
  phenotype: string;
}

export type OidcProvider = 'google' | 'microsoft' | 'auth0' | undefined;
