import { Box, MuiAppBar, MuiDivider, Text, Toolbar, getMuiClassSelector } from '@color/continuum';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { NavTabs } from 'components/common/NavTabs/NavTabs';
import { UserInfo, useColorQuery } from 'lib/api';
import { CAN_MANAGE_REPORTS } from 'lib/constants';

import { Logo } from './Logo';
import { UserMenu } from './UserMenu';

/**
 * TODO - Migrate to shared components AppBar, PC-162 https://getcolor.atlassian.net/browse/PC-162
 */
export const Nav = () => {
  const { isLoading, isError, error, data: userInfo } = useColorQuery<UserInfo>(`/userinfo`);
  const flags = useFlags();

  if (isError) {
    return <Text>{`${error}`}</Text>;
  }
  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  // @rohittalwalkar - useColorQuery might return "undefined"
  if (userInfo === undefined) {
    return <Text>Loading...</Text>;
  }

  const tabs = [{ label: 'Dashboard', path: '/dashboard' }];
  if (!flags.hideVariantsList) {
    tabs.push({ label: 'Variants', path: '/variants' });
  }
  if (userInfo.permissions.includes(CAN_MANAGE_REPORTS)) {
    tabs.push({ label: 'Reports', path: '/reports' });
  }

  return (
    <MuiAppBar
      role="navigation"
      sx={(theme) => ({ boxShadow: 'none', background: theme.palette.background.navBarBg })}
    >
      <Toolbar
        sx={{
          background: 'transparent',
          alignItems: 'stretch',
          padding: '0',
          paddingLeft: '24px',
          height: '72px',
          [getMuiClassSelector('& .MuiTab-root')]: {
            opacity: 1, // Inactive tabs have black text in the Nav bar, but gray text normally
          },
        }}
      >
        <Box display="flex">
          <Logo />
          <MuiDivider
            orientation="vertical"
            sx={{
              alignSelf: 'center',
              height: '56px',
              margin: '0 24px',
            }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          <NavTabs tabs={tabs} defaultRoute="" />
          <UserMenu />
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};
