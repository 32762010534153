import { Tab, Tabs, getMuiClassSelector } from '@color/continuum';
import { NavLink, matchPath, useLocation } from 'react-router-dom';

const TABS_INDICATOR_HEIGHT = '4px';
const TAB_MIN_WIDTH = '104px';

export interface Props {
  tabs: {
    label: string;
    path: string;
  }[];
  defaultRoute: string;
  onChange?: () => void;
}

export const NavTabs = (props: Props) => {
  const { tabs, defaultRoute, onChange } = props;
  const { pathname, hash } = useLocation();

  let hashRoute = hash;
  if (hashRoute === '' && defaultRoute) {
    hashRoute = defaultRoute;
  }

  const activeTab = tabs.find((tab) => matchPath(pathname, tab.path))
    ? tabs.find((tab) => matchPath(pathname, tab.path))
    : tabs.find((tab) => matchPath(hashRoute, tab.path));

  return (
    <Tabs
      aria-label="tabs"
      sx={{
        [getMuiClassSelector('& .MuiTabs-flexContainer')]: {
          height: '100%',
        },
        background: 'transparent',
      }}
      TabIndicatorProps={{
        sx: {
          height: TABS_INDICATOR_HEIGHT,
        },
      }}
      onChange={onChange}
      value={activeTab?.path || false}
    >
      {tabs.map(({ label, path }, index) => (
        <Tab<NavLink>
          component={NavLink}
          sx={{
            minWidth: TAB_MIN_WIDTH,
          }}
          key={label}
          value={path}
          label={label}
          to={path}
          id={`tab-${index}`}
          aria-controls={`tabpanel-${index}`}
        />
      ))}
    </Tabs>
  );
};
