import { Spacer, Text } from '@color/continuum';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { camelCase } from 'lodash';
import { Fragment } from 'react';

import { DisplayError } from 'components/DisplayError';
import { UserInfo, useColorQuery } from 'lib/api';
import { InterpretationStatus, TaskType, VariantTask } from 'lib/types';

import { AwaitingHrdfSummaryCard } from './AwaitingHrdfSummaryCard';
import { UnresolvedChallengeSummaryCard } from './UnresolvedChallengeSummaryCard';
import { VacMemberTaskCards } from './VacMemberTaskCards';
import { VariantScientistTaskCard } from './VariantScientistTaskCard';

const filterDuplicates = (tasks: VariantTask[]) => {
  // Each task type may contain the same variant. If there are variants that are discordant AND
  // in another state, remove tasks of type Discordance with the variant appearing in other states
  const taskTypeWithPrecedence = [TaskType.UnaddressedAcceptance, TaskType.UnresolvedChallenge];
  const idsOfVariantsToRemove = tasks
    .filter((task) => taskTypeWithPrecedence.includes(task.type))
    .map((task) => task.variant.id);
  return tasks.filter(
    (task) =>
      !(task.type === TaskType.Discordance && idsOfVariantsToRemove.includes(task.variant.id))
  );
};

interface QueryResults {
  results: VariantTask[];
}

export interface VariantTaskCardsProps {
  userInfo: UserInfo;
}

export const VariantTaskCards: React.FC<VariantTaskCardsProps> = (props: VariantTaskCardsProps) => {
  const { userInfo } = props;
  const { useNewDashboardApi, rhpDashboardImprovements } = useFlags();
  const variantsQuery = useColorQuery<QueryResults>(`/extendedvariants/tasks`);
  if (variantsQuery.isError) {
    return <DisplayError {...variantsQuery.error} />;
  }
  if (variantsQuery.isLoading || variantsQuery.isIdle) {
    return <Text>Loading...</Text>;
  }
  // If other checks are false, variants.isSuccess == true
  const variantTasks = variantsQuery.data.results.map((task) => {
    // eslint-disable-next-line no-param-reassign
    task.type = camelCase(task.type);
    return task;
  });
  const deduplicatedVariantTasks = filterDuplicates(variantTasks);
  const filteredTasks = deduplicatedVariantTasks.filter(
    (task) => task.variant.interpretationStatus !== InterpretationStatus.HARMONIZED
  );
  const isVacMember = userInfo.permissions.includes('can_resolve_interpretation_challenge');
  const isReporter = userInfo.permissions.includes('can_manage_reports');
  return (
    <>
      {isVacMember && !useNewDashboardApi && <VacMemberTaskCards />}
      {useNewDashboardApi && <UnresolvedChallengeSummaryCard />}
      {rhpDashboardImprovements && isReporter && (
        <>
          <Spacer variant="small" />
          <AwaitingHrdfSummaryCard />
        </>
      )}
      {!useNewDashboardApi &&
        filteredTasks.map((variantTask) => (
          <Fragment key={variantTask.type + variantTask.variant.id}>
            <Spacer variant="small" />
            <VariantScientistTaskCard
              task={variantTask}
              cvl={userInfo.cvl}
              type={variantTask.type}
            />
          </Fragment>
        ))}
    </>
  );
};
