import { AouDashboardCard, Image, Text } from '@color/continuum';

import clipboardImage from 'assets/images/clipboard.svg';
import { useColorQuery } from 'lib/api';

export const ManualHoldSummaryCard: React.FC = () => {
  const data = useColorQuery<{ numReportsOnHold: number }>('/dashboard/reports_on_hold', {
    searchParams: { report_state: 'manual_hold' },
  });
  if (data.isLoading) {
    return <Text>...loading</Text>;
  }
  if (data.isError) {
    return <Text>Error: {data.error.errorMessage}</Text>;
  }
  if (!data.data) {
    return <Text>No data</Text>;
  }
  if (data.data.numReportsOnHold === 0) {
    return <></>;
  }
  return (
    <AouDashboardCard
      title={`${data.data.numReportsOnHold} reports on manual hold`}
      icon={
        <Image
          src={clipboardImage}
          alt="Clipboard icon"
          sx={{
            height: '72px',
          }}
        />
      }
      buttonLinkTo="/reports#on_hold"
      buttonContent="Review reports"
      buttonVariant="color-primary"
    />
  );
};
