import { Box, Divider, ExternalLink, Grid, Spacer, Text, Title } from '@color/continuum';
import LaunchIcon from '@mui/icons-material/Launch';

import { getVariantTitle } from 'components/Variants/util';
import { Variant } from 'lib/types';

interface Props {
  variants: Variant[];
}

const ClassificationRow: React.FC<{ variant: Variant }> = (props) => {
  const { variant } = props;
  return (
    <>
      <Title variant="headline2">
        <i>{getVariantTitle(variant)}</i>
      </Title>
      {variant.cvlInterpretations.map((interpretation) => {
        const {
          cvl,
          classification,
          classificationWriteUp: summary,
          variantDetailsLink: link,
        } = interpretation;
        return (
          <Grid container>
            <Grid item xs={12}>
              <Divider aria-hidden="true" />
              <Spacer variant="xxxLarge" />
            </Grid>
            <Grid item xs={3}>
              <Title variant="headline3">{cvl}</Title>
            </Grid>
            <Grid item xs={5}>
              <Title variant="headline2">{`Classification: ${classification}`}</Title>
              <Spacer variant="small" />
              {/* TODO @rohittalwalkar - figure out the "show more" logic */}
              <Text>{summary}</Text>
              <Spacer variant="medium" />
              <ExternalLink to={link}>
                Go to External Link <LaunchIcon height="18px" />
              </ExternalLink>
              <Spacer variant="medium" />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        );
      })}
    </>
  );
};

/**
 * FIXME Break this into separate files. Color standard is one component per file.
 */
export const VariantsTabClassifications: React.FC<Props> = (props) => {
  const { variants } = props;
  return (
    <Box>
      <Divider aria-hidden="true" />
      <Spacer variant="large" />
      <Title>Classification Details</Title>
      <Spacer variant="small" />
      {variants.map((variant) => (
        <ClassificationRow key={variant.id} variant={variant} />
      ))}
    </Box>
  );
};
