import { Grid, MuiTextField, MultipleSelect, Text } from '@color/continuum';

import { TestTypes } from 'lib/constants';

const testTypeOptions = (Object.keys(TestTypes) as Array<TestTypes>).map((key) => ({
  key,
  value: TestTypes[key],
}));

interface Props {
  search: string;
  setSearch: (search: string) => void;
  selectedTestTypes: Set<TestTypes>;
  setSelectedTestTypes: (selected: Set<TestTypes>) => void;
}

export const ReportsFilters: React.FC<Props> = (props) => {
  const { search, setSearch, selectedTestTypes, setSelectedTestTypes } = props;
  return (
    <Grid container spacing={2} justifyContent="space-evenly">
      <Grid item xs={8}>
        <Text bold>Search by</Text>
      </Grid>
      <Grid item xs={4}>
        <Text bold>Filter by</Text>
      </Grid>
      <Grid item xs={8}>
        <MuiTextField
          id="biobank-sample-search"
          type="search"
          InputProps={{
            placeholder: 'Biobank, sample or report ID',
          }}
          onChange={(event) => setSearch((event.target as HTMLInputElement).value)}
          value={search}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <MultipleSelect
          label="Test type"
          options={testTypeOptions}
          selected={selectedTestTypes}
          setSelected={(statuses) => setSelectedTestTypes(statuses as Set<TestTypes>)}
          optionalIndicatorIsEnabled={false}
        />
      </Grid>
    </Grid>
  );
};
