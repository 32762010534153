import { Box, CommonDialog } from '@color/continuum';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { OnHoldDialogFields } from './OnHoldDialogFields';

interface Props {
  isOpen: boolean;
  onSubmit: (holdReason: string) => void;
  onCancel: () => void;
}

export const OnHoldDialog: React.FC<Props> = (props) => {
  const { onSubmit, onCancel, isOpen } = props;

  return (
    <CommonDialog dialogIsOpen={isOpen} title="Put report on hold" onClose={onCancel}>
      {/* FIXME @jazeee: This should use a standard `DialogContent` component along with theme based styling */}
      <Box width="600px" alignContent="center" padding={3}>
        <Formik
          initialValues={{
            holdReason: '',
          }}
          validationSchema={Yup.object().shape({
            holdReason: Yup.string().required('Required'),
          })}
          onSubmit={(values) => {
            onSubmit(values.holdReason);
          }}
        >
          <Form>
            <OnHoldDialogFields onCancel={onCancel} />
          </Form>
        </Formik>
      </Box>
    </CommonDialog>
  );
};
