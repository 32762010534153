import { Entries } from '@color/lib';
import { capitalize } from 'lodash';

import {
  AouInterpretation,
  ClinicalAnalysisType,
  CvlInterpretation,
  DisplayDifference,
  HarmonizeFields,
  SignificantGene,
} from './types';

export const EMPTY_VALUE = '--';

export const getDiscordantFields = (rhpFields: HarmonizeFields, labFields: HarmonizeFields) => {
  const uninformativeClassifications = [
    'Uncertain Significance',
    'Likely Benign',
    'Benign',
    'Not Assigned',
  ];

  const labIsNonReportable = uninformativeClassifications.includes(labFields.classification);
  const rhpIsNonReportable = rhpFields.classification === 'Uninformative';

  // need at least one of them to be reportable for any fields to be considered different
  if (labIsNonReportable && rhpIsNonReportable) {
    return [];
  }

  const differences = [] as Array<DisplayDifference>;
  (Object.entries(rhpFields) as Entries<HarmonizeFields>).forEach(([key, value]) => {
    if (key === 'classification') {
      if (value === 'Uninformative' && !uninformativeClassifications.includes(labFields[key])) {
        differences.push({ aou: value, lab: labFields[key], caption: key });
      } else if (value !== labFields[key] && value !== 'Uninformative') {
        differences.push({ aou: value, lab: labFields[key], caption: key });
      }
    } else if (key === 'transcript') {
      if (value.split('.')[0] !== labFields[key].split('.')[0]) {
        differences.push({ aou: value, lab: labFields[key], caption: key });
      }
    } else if (value !== labFields[key]) {
      // TODO @rohittalwalkar - fix caption
      differences.push({ aou: value, lab: labFields[key], caption: key });
    }
  });
  return differences;
};
const dateFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

const parseDate = (dateString: string) => {
  const rawDate = dateString.substring(0, 10);
  const dateParts = rawDate.split('-');
  return new Date(
    parseInt(dateParts[0], 10),
    parseInt(dateParts[1], 10) - 1, // account for monthIndex vs. month
    parseInt(dateParts[2], 10)
  );
};

/**
 * Takes in a date string of any length of 10 or greater characters with the expected format of
 * YYYY-MM-DD. The remainder of the input dateString is ignored. The expectation of MM is that
 * it is 1-based, meaning January = 01, which is standard outside of Javascript, where the input
 * for the month is expected to be 0-based and 0 = January
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#parameters
 * @param dateString
 */
export const formatDateString = (dateString: string) => {
  if (typeof dateString === 'undefined') {
    return '';
  }
  return dateFormat.format(parseDate(dateString));
};

// We need to have a value in the cvl field or there will be a lot of errors
const KEY_TO_IGNORE = 'cvl';
export const noFieldsHaveValues = (cvlData: CvlInterpretation | AouInterpretation) =>
  Object.entries(cvlData).every(
    ([key, value]) =>
      key === KEY_TO_IGNORE || value.toString() === EMPTY_VALUE || value.toString() === ''
  );

export const getCvlData = (
  cvl: string,
  interpretations: CvlInterpretation[],
  emptyValue = EMPTY_VALUE
): CvlInterpretation => {
  const items = interpretations.filter((item) => item.cvl === cvl);
  const cvlData = items.length === 1 ? items[0] : ({ cvl, lowPenetrance: '' } as CvlInterpretation);

  // @rohittalwalkar - convert the boolean to a string and capitalize to match the All of Us value
  const lowPenetrance = capitalize(cvlData.lowPenetrance.toString());
  return {
    cvl: cvlData.cvl,
    classification: cvlData.classification || emptyValue,
    lowPenetrance: lowPenetrance || emptyValue,
    cHgvs: cvlData.cHgvs || emptyValue,
    pHgvs: cvlData.pHgvs || emptyValue,
    gHgvs: cvlData.gHgvs || emptyValue,
    transcript: cvlData.transcript || emptyValue,
    disorder: cvlData.disorder || emptyValue,
    variantDetailsLink: cvlData.variantDetailsLink || emptyValue,
    classificationWriteUp: cvlData.classificationWriteUp || emptyValue,
    classificationDate: formatDateString(cvlData.classificationDate) || emptyValue,
    createdAt: formatDateString(cvlData.createdAt) || emptyValue,
  } as CvlInterpretation;
};

export const openReportInNewTab = (reportId: number, analysisType: string) => {
  let url = '';
  if (analysisType === ClinicalAnalysisType.Hdrv1) {
    url = `/reports/${reportId}/variants`;
  } else {
    url = `/reports/${reportId}/alleles`;
  }
  window.open(url, '_blank');
};

export const getReportSignificanceText = (
  analysisType: string,
  significantGenes: SignificantGene[] | null
): string[] => {
  if (analysisType.toLowerCase().startsWith('pgx')) {
    return ['PGx'];
  }
  if (significantGenes === null || significantGenes.length === 0) {
    return ['Uninformative'];
  }
  return significantGenes.map(
    (significantGene) => `${significantGene.gene}: ${significantGene.classification}`
  );
};

export const isFieldDiscordant = (
  aouData: AouInterpretation,
  cvlData: CvlInterpretation | AouInterpretation,
  field: string,
  isReportable: boolean
) => {
  if (!isReportable) {
    return false;
  }
  if (field === 'createdAt') {
    return false;
  }
  if (noFieldsHaveValues(cvlData)) {
    return false;
  }
  if (
    (aouData[field as keyof AouInterpretation] === '' ||
      aouData[field as keyof AouInterpretation] === null) &&
    cvlData[field as keyof AouInterpretation] === EMPTY_VALUE
  ) {
    return false;
  }
  if (field === 'classification') {
    const uninformativeClassifications = [
      'Uncertain Significance',
      'Likely Benign',
      'Benign',
      'Not Assigned',
    ];
    if (
      aouData[field] === 'Uninformative' &&
      uninformativeClassifications.includes(cvlData[field])
    ) {
      return false;
    }
  }
  if (field === 'transcript') {
    return cvlData.transcript.split('.')[0] !== aouData.transcript.split('.')[0];
  }
  return aouData[field as keyof AouInterpretation] !== cvlData[field as keyof AouInterpretation];
};

const englishOrdinalRules = new Intl.PluralRules('en', { type: 'ordinal' });

const suffixes = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
};

export const formatOrdinal = (number: number): string => {
  const category = englishOrdinalRules.select(number) as keyof typeof suffixes;
  const suffix = suffixes[category];
  return number + suffix;
};
