import { Box, Grid, InternalLink, Spacer, Text, Title } from '@color/continuum';
import { Fragment } from 'react';

import { AlertCard } from 'components/common/AlertCard';
import { Revision, RevisionReason } from 'lib/types';

export interface Props {
  revisionReason?: RevisionReason;
  revisions: Revision[];
  latest: boolean;
}

const DOWNGRADE_EXPLANATION = (
  <Text>
    <strong>Participant-facing explanation:</strong> Previously, your <em>All of Us</em> Hereditary
    Disease Risk result indicated that we found something significant for your health in the genes
    we looked at, but we have now identified an important update.{' '}
    <strong>
      Your result has changed and it no longer suggests something significant for your health in the
      genes we looked at.
    </strong>{' '}
    Please see the report below, which summarizes the most up-to-date information about your
    research result.
  </Text>
);
const UPGRADE_EXPLANATION = (
  <Text>
    <strong>Participant-facing explanation:</strong> Previously, your <em>All of Us</em> Hereditary
    Disease Risk result indicated that we did not find anything significant for your health in the
    genes we looked at, but we have now identified an important update.{' '}
    <strong>
      Something very important for your health was found and you should talk with your doctor.
    </strong>{' '}
    Please see the report below, which summarizes the most up-to-date information about your
    research result.
  </Text>
);

const REVISION_REASON_EXPLANATION = {
  [RevisionReason.Upgrade]: UPGRADE_EXPLANATION,
  [RevisionReason.Downgrade]: DOWNGRADE_EXPLANATION,
};

export const RevisionNotification: React.FC<Props> = (props) => {
  const { revisionReason, revisions, latest } = props;

  let alertTitle = 'This report is outdated';
  let explanation = (
    <>
      <Box>
        <Title variant="headline3">
          <strong>This report has since been revised</strong>
        </Title>
      </Box>
      <Spacer variant="medium" />
    </>
  );
  if (latest) {
    alertTitle = 'This is a revised report';
    explanation = (
      <>
        <Box>
          <Text>
            <strong>Reason for revision:</strong> Variant interpretation change
          </Text>
        </Box>
        <Spacer variant="medium" />
        {revisionReason && <Box>{REVISION_REASON_EXPLANATION[revisionReason]}</Box>}
        <Spacer variant="medium" />
      </>
    );
  }

  return (
    <AlertCard alertSeverity="info" alertTitle={alertTitle}>
      <Grid item xs={1} />
      <Grid item xs={11}>
        <Spacer variant="small" />
        <Grid container>
          <Grid item xs={10}>
            <Box>
              {explanation}
              <Box>
                <Text>
                  <b>Jump to:</b>
                </Text>
                {revisions.map((revision) => {
                  return (
                    <Fragment key={revision.revision}>
                      <Spacer variant="xSmall" />
                      <InternalLink to={`/reports/${revision.id}/reports`} sx={{ paddingLeft: 1 }}>
                        Report revision {revision.revision + 1}
                      </InternalLink>
                    </Fragment>
                  );
                })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </AlertCard>
  );
};
