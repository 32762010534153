import { ClassificationInfo, CvlInfo, StatusInfo } from './types';

const Baylor: CvlInfo = {
  fullName: 'Baylor',
  slug: 'baylor',
};

const Color: CvlInfo = {
  fullName: 'Color',
  slug: 'color',
};

const Uw: CvlInfo = {
  fullName: 'U. of Washington',
  slug: 'uw',
};

const BCM: CvlInfo = {
  fullName: 'Baylor',
  slug: 'bcm',
};

const CO: CvlInfo = {
  fullName: 'Color',
  slug: 'CO',
};

const LMM: CvlInfo = {
  fullName: 'fix data - LMM for cvl',
  slug: 'LMM',
};

const UW: CvlInfo = {
  fullName: 'University of Washington',
  slug: 'uw',
};

export const Cvl = {
  Baylor,
  Color,
  Uw,
  BCM,
  CO,
  LMM,
  UW,
};

export type CvlKey = keyof typeof Cvl;

const Harmonized: StatusInfo = {
  fullName: 'Harmonized',
  slug: 'HARMONIZED',
};

const Discordant: StatusInfo = {
  fullName: 'Discordant',
  slug: 'DISCORDANT',
};

const NonHarmonized: StatusInfo = {
  fullName: 'Non-harmonized',
  slug: 'UNHARMONIZED',
};

const Challenged: StatusInfo = {
  fullName: 'Challenged',
  slug: 'UNRESOLVED_CHALLENGE',
};

export const Status = {
  Harmonized,
  Discordant,
  NonHarmonized,
  Challenged,
};

export type StatusKey = keyof typeof Status;

const LikelyPathogenic: ClassificationInfo = {
  displayName: 'Likely pathogenic',
  internalName: 'Likely Pathogenic',
};

const NonReportable: ClassificationInfo = {
  displayName: 'Non-reportable',
  internalName: 'Uninformative',
};

const Pathogenic: ClassificationInfo = {
  displayName: 'Pathogenic',
  internalName: 'Pathogenic',
};

const RiskAllele: ClassificationInfo = {
  displayName: 'Risk allele',
  internalName: 'Risk Allele',
};
const Benign: ClassificationInfo = {
  displayName: 'Benign',
  internalName: 'Benign',
};

const LikelyBenign: ClassificationInfo = {
  displayName: 'Likely benign',
  internalName: 'Likely Benign',
};

const UncertainSignificance: ClassificationInfo = {
  displayName: 'Uncertain significance',
  internalName: 'Uncertain Significance',
};

const NotAssigned: ClassificationInfo = {
  displayName: 'Not assigned',
  internalName: 'Not Assigned',
};

export const Classification = {
  Pathogenic,
  LikelyPathogenic,
  RiskAllele,
  NonReportable,
};

export const CvlClassification = {
  Pathogenic,
  LikelyPathogenic,
  RiskAllele,
  UncertainSignificance,
  LikelyBenign,
  Benign,
  NotAssigned,
};

export type ClassificationKey = keyof typeof Classification;

export const Paths = {
  variants: 'variants',
  reports: 'reports',
  dashboard: 'dashboard',
};

export enum TestTypes {
  HDRV1 = 'HDRV1',
  PGxV1 = 'PGxV1',
}

// @rohittalwalkar
// copied from class Disorder(StrEnum):
export enum AssociatedDisorders {
  ARRHYTHMOGENIC_CARDIOMYOPATHY = 'ACM',
  ARRHYTHMOGENIC_CARDIOMYOPATHY_AND_DILATED_CARDIOMYOPATHY = 'ACMDCM',
  BRUGADA_SYNDROME = 'BRS',
  CATECHOLAMINERGIC_POLYMORPHIC_VENTRICULAR_TACHYCARDIA = 'CPVT',
  DILATED_CARDIOMYOPATHY = 'DCM',
  FABRY_DISEASE = 'FD',
  FAMILIAL_ADENOMATOUS_POLYPOSIS = 'FAP',
  FAMILIAL_HYPERCHOLESTEROLEMIA = 'FH',
  FAMILIAL_THORACIC_AORTIC_ANEURYSM_AND_AORTIC_DISSECTION = 'TAAD',
  HEREDITARY_BREAST_AND_OVARIAN_CANCER_SYNDROME = 'HBOC',
  HYPERTROPHIC_CARDIOMYOPATHY = 'HCM',
  JUVENILE_POLYPOSIS_SYNDROME = 'JPS',
  LI_FRAUMENI_SYNDROME = 'LFS',
  LOEYS_DIETZ_SYNDROME = 'LDS',
  LONG_QT_SYNDROME = 'LQTS',
  LYNCH_SYNDROME = 'HNPCC',
  MALIGNANT_HYPERTHERMIA_SUSCEPTIBILITY = 'MHS',
  MARFAN_SYNDROME = 'MFS',
  MULTIPLE_ENDOCRINE_NEOPLASIA_TYPE_1 = 'MEN1',
  MULTIPLE_ENDOCRINE_NEOPLASIA_TYPE_2 = 'MEN2',
  MUTYH_ASSOCIATED_POLYPOSIS = 'MAP',
  NEUROFIBROMATOSIS_TYPE_2 = 'NF2',
  ORNITHINE_CARBAMOYLTRANSFERASE_DEFICIENCY = 'OTC',
  PARAGANGLIOMAS_1 = 'PGL1',
  PARAGANGLIOMAS_2 = 'PGL2',
  PARAGANGLIOMAS_3 = 'PGL3',
  PARAGANGLIOMAS_4 = 'PGL4',
  PEUTZ_JEGHERS_SYNDROME = 'PJS',
  PTEN_HAMARTOMA_TUMOR_SYNDROME = 'PHTS',
  RETINOBLASTOMA = 'RB',
  TUBEROUS_SCLEROSIS_COMPLEX = 'TSC',
  VASCULAR_EHLERS_DANLOS_SYNDROME = 'VEDS',
  VON_HIPPEL_LINDAU_SYNDROME = 'VHL',
  WILMS_TUMOR = 'WT',
  WILSON_DISEASE = 'WD',
}

export const VariantFieldToDisplayCaption = {
  classification: 'Classification',
  lowPenetrance: 'Low penetrance flag',
  disorder: 'Associated disorder',
  transcript: 'Transcript',
  cHGVS: 'cHGVS',
  gHGVS: 'gHGVS',
  pHGVS: 'pHGVS',
};

export const HGVS_FIELDS = new Set(['cHGVS', 'pHGVS', 'gHGVS']);

export const VAC_FIELDS = [
  'classification',
  'lowPenetrance',
  'disorder',
  'transcript',
  'cHGVS',
  'gHGVS',
  'pHGVS',
] as const;

// @rohittalwalkar - disorder is required for reportable variants, but we'll make optional and let the BE validate
export const REQUIRED_VAC_FIELDS = [
  'classification',
  'lowPenetrance',
  'transcript',
  'cHGVS',
  'gHGVS',
] as const;

export const VAC_PERMISSION_CODENAME = 'can_resolve_interpretation_challenge';
export const CAN_MANAGE_REPORTS = 'can_manage_reports';

export const MFA_PAGE_URL = '/mfa';
