import { camelCase, chain, isArray, isPlainObject, snakeCase } from 'lodash';

import { JsonObject } from '../types';

type ConvertKeyFunction = (key: string) => string;

// Recursively converts all object keys given a converter function
// Adapted from https://stackoverflow.com/a/50620653
function convertKeys<ObjectType extends JsonObject>(
  object: ObjectType,
  convertKey: ConvertKeyFunction
): ObjectType {
  if (isPlainObject(object)) {
    return chain(object)
      .toPairs()
      .map(([key, value]) => [convertKey(key), convertKeys(value, convertKey)])
      .fromPairs()
      .value() as ObjectType;
  }
  if (isArray(object)) {
    return object.map((value) => convertKeys(value, convertKey)) as ObjectType;
  }
  return object;
}

export function convertKeysToCamelCase<ObjectType extends JsonObject>(
  object: ObjectType
): ObjectType {
  return convertKeys(object, camelCase);
}

export function convertKeysToSnakeCase<ObjectType extends JsonObject>(
  object: ObjectType
): ObjectType {
  return convertKeys(object, snakeCase);
}
