import { useComponentsMessages } from '@color/continuum';
import { CookiesProvider, Locale } from '@color/lib';
import { SnackbarProvider } from 'notistack';
import { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import { OidcProvider } from 'lib/types';

import { AppRoutes } from './components/AppRoutes';
import { ApiQueryClientProvider } from './Providers/ApiQueryClientProvider';
import { AuthProvider } from './Providers/AuthProvider';
import { LaunchDarklyProvider } from './Providers/LaunchDarklyProvider';
import { ThemeProvider } from './Providers/ThemeProvider';

export const App = () => {
  const locale = Locale.ENGLISH;
  const componentsMessages = useComponentsMessages({ locale });
  const [oidcProvider, setOidcProvider] = useState<OidcProvider>(undefined);

  return (
    <HelmetProvider>
      <CookiesProvider>
        <ThemeProvider>
          <IntlProvider locale={locale} messages={componentsMessages}>
            <BrowserRouter>
              <ApiQueryClientProvider>
                <AuthProvider oidcProvider={oidcProvider}>
                  <SnackbarProvider
                    anchorOrigin={{
                      horizontal: 'center',
                      vertical: 'top',
                    }}
                    preventDuplicate
                  >
                    <LaunchDarklyProvider>
                      <AppRoutes oidcProvider={oidcProvider} setOidcProvider={setOidcProvider} />
                    </LaunchDarklyProvider>
                  </SnackbarProvider>
                </AuthProvider>
              </ApiQueryClientProvider>
            </BrowserRouter>
          </IntlProvider>
        </ThemeProvider>
      </CookiesProvider>
    </HelmetProvider>
  );
};
