import { Box, PageContainer, Spacer, Stack, Text } from '@color/continuum';

import { Logo } from 'components/App/Nav/Logo';
import { OTP_PATH_URL_KEY } from 'lib/api';

import { Page } from '../common/Page';
import { MfaRegister } from './MfaRegister';
import { MfaVerify } from './MfaVerify';

export const MfaPage = () => {
  const otpauth = window.sessionStorage.getItem(OTP_PATH_URL_KEY);

  return (
    <Page requiresAuth={false}>
      <PageContainer helmetTitle="MFA">
        <Spacer variant="xxLarge" />
        <Box display="flex" justifyContent="center">
          <Logo />
        </Box>
        <Spacer variant="xxLarge" />
        {otpauth && otpauth.startsWith('otpauth://totp') && <MfaRegister otpauth={otpauth} />}
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
          <Text>Enter the code from your authenticator app</Text>
          <MfaVerify />
        </Stack>
      </PageContainer>
    </Page>
  );
};
