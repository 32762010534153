import { Environment } from '@color/lib';

import { AppConfig } from './types';

export const config: AppConfig = {
  API_ROOT: '/api/v1',
  ENVIRONMENT: Environment.LOCAL,
  LAUNCHDARKLY_CLIENT_ID: null,
  SENTRY_DSN: null,
  USE_REFRESH_TOKENS: true,
  VARIANT_JOTFORM: 'https://color.jotform.com/240525329610046',
  OIDC_PROVIDERS: {
    google: {
      authority: 'https://accounts.google.com',
      client_id: '873731912317-oglnd5j2srdmulitggc5b6n374va50mi.apps.googleusercontent.com',
      client_secret: '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', // Not an actual secret (SPA client)
    },
    microsoft: {
      authority: 'https://login.microsoftonline.com/common/v2.0',
      client_id: '7d48a573-c659-4351-850f-c696df065447',
      client_secret: null,
    },
    auth0: {
      authority: 'https://aou-rhp-local.us.auth0.com',
      client_id: 'VVK6oNKuBCb2REtXuidT2eHUCe5n3ZIN',
      client_secret: null,
    },
  },
};
