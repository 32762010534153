import { ConfirmCancelDialog, Text } from '@color/continuum';

import { DisplayDifference } from 'lib/types';

interface Props {
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
  differences: DisplayDifference[];
}

export const AcceptDialog: React.FC<Props> = (props) => {
  const { onAccept, onCancel, isOpen, differences } = props;
  return (
    <ConfirmCancelDialog
      dialogIsOpen={isOpen}
      title="Accept All of Us variant interpretation"
      onAccept={onAccept}
      onCancel={onCancel}
    >
      <Text>
        You are about to accept:
        <b>
          <ul>
            {differences.map((diff) => (
              <li key={diff.caption}>{`All of Us ${diff.caption}: ${diff.aou}`}</li>
            ))}
          </ul>
        </b>
      </Text>
      <Text>
        Once you accept the <i>All of Us</i> variant interpretation,{' '}
        <b>
          any report with this variant will automatically be put on hold. You will need to submit a
          revised health-related data file.
        </b>
      </Text>
    </ConfirmCancelDialog>
  );
};
