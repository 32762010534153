import { AouDashboardCard, Image, Spacer, Text } from '@color/continuum';

import dnaImage from 'assets/images/dna.svg';
import { useColorQuery } from 'lib/api';

interface QueryResults {
  numUnresolvedChallenges: number;
  numReportsOnHold: number;
}

export const UnresolvedChallengeSummaryCard: React.FC = () => {
  const data = useColorQuery<QueryResults>('/dashboard/vac_tasks');
  if (data.isLoading) {
    return <Text>...loading</Text>;
  }
  if (data.isError) {
    return <Text>Error: {data.error.errorMessage}</Text>;
  }
  if (!data.data) {
    return <Text>No data</Text>;
  }
  const hasChallenges = data.data.numUnresolvedChallenges > 0;
  return hasChallenges ? (
    <>
      <Spacer variant="small" />
      <AouDashboardCard
        title={`Resolve ${data.data.numUnresolvedChallenges} variants`}
        subtitle={`Blocking ${data.data.numReportsOnHold} reports`}
        icon={
          <Image
            src={dnaImage}
            alt="DNA"
            sx={{
              height: '72px',
            }}
          />
        }
        buttonLinkTo="/variants?filter=challenged"
        buttonContent="Resolve Variants"
        buttonVariant="color-primary"
      />
    </>
  ) : (
    <></>
  );
};
