import { ExternalLink, Spacer, Text, Title } from '@color/continuum';
import { Keys } from '@color/lib';
import WarningIcon from '@mui/icons-material/Warning';

import { DataTable, DataTableRow } from 'components/common/DataTable/DataTable';
import { Cvl, CvlKey } from 'lib/constants';
import { ClassificationInfo as ClassificationInfoType, InterpretationStatus } from 'lib/types';
import { isFieldDiscordant } from 'lib/utils';

export interface CvlClassificationInfo {
  classification: ClassificationInfoType;
  classificationWriteUp: string;
  variantDetailsLink: string;
}

export type CvlClassificationInfoMap = { [cvl in CvlKey]?: CvlClassificationInfo };

interface ClassificationInfoProps {
  classificationInfo: CvlClassificationInfoMap;
  rhpClassification: string;
  isReportable: boolean;
}

export const ClassificationInfo: React.FC<ClassificationInfoProps> = (props) => {
  const { classificationInfo, rhpClassification, isReportable } = props;
  return (
    <DataTable>
      {(Object.keys(classificationInfo) as Keys<CvlClassificationInfoMap>)
        .sort()
        .map((cvl: keyof CvlClassificationInfoMap) => {
          const { classification, classificationWriteUp, variantDetailsLink } =
            classificationInfo[cvl]!;
          const cvlName = Cvl[cvl].fullName;
          return (
            <DataTableRow key={cvlName} name={cvlName}>
              <Spacer variant="large" />
              <Title variant="headline3">{`Classification: ${classification.displayName}`}</Title>
              {isReportable &&
                isFieldDiscordant(
                  {
                    classification: rhpClassification,
                    lowPenetrance: '',
                    cHgvs: '',
                    pHgvs: '',
                    gHgvs: '',
                    transcript: '',
                    disorder: '',
                    createdAt: '',
                  },
                  {
                    classification: classification.internalName,
                    id: 0,
                    status: InterpretationStatus.HARMONIZED,
                    cvl: '',
                    variantDetailsLink: '',
                    classificationWriteUp: '',
                    classificationDate: '',
                    unresolvedChallenge: {} as any,
                    unaddressedNewFileRequest: {} as any,
                    lowPenetrance: '',
                    cHgvs: '',
                    pHgvs: '',
                    gHgvs: '',
                    transcript: '',
                    disorder: '',
                    createdAt: '',
                  },
                  'classification',
                  isReportable
                ) && (
                  <Text>
                    <WarningIcon
                      sx={(theme) => ({
                        color: theme.palette.error.main,
                      })}
                    />
                    {`This classification conflicts with the current ${rhpClassification} classification`}
                  </Text>
                )}
              <Spacer variant="xSmall" />
              <Text>{classificationWriteUp}</Text>
              <Spacer variant="large" />
              <Spacer variant="large" />
              {/* TODO @devchakraborty: Update link to CVL tools */}
              <Text variant="body2" bold>
                <ExternalLink
                  to={variantDetailsLink}
                  linkShouldOpenInCurrentTab={false}
                  sx={(theme) => ({
                    color: theme.palette.info.main,
                    textDecoration: 'none',
                  })}
                >
                  Go to {cvlName} internal variant tools
                </ExternalLink>
              </Text>
              <Spacer variant="large" />
            </DataTableRow>
          );
        })}
    </DataTable>
  );
};
