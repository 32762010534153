import { ConfirmCancelDialog, Text } from '@color/continuum';

import { DisplayDifference } from 'lib/types';

interface Props {
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
  differences: DisplayDifference[];
}

export const ChallengeDialog: React.FC<Props> = (props) => {
  const { onAccept, onCancel, isOpen, differences } = props;
  const title = 'Challenge All of Us variant interpretation';
  return (
    <ConfirmCancelDialog
      dialogIsOpen={isOpen}
      title={title}
      onAccept={onAccept}
      onCancel={onCancel}
      confirmButtonText="I understand"
    >
      <Text>
        You are about to challenge:
        <b>
          <ul>
            {differences.map((diff) => (
              <li key={diff.caption}>{`All of Us ${diff.caption}: ${diff.aou}`}</li>
            ))}
          </ul>
        </b>
      </Text>
      <Text>
        Once you submit the request, this variant will be sent to the Variant Adjudication Committee
        for review.{' '}
        <b>
          All the reports with this variant interpretation that haven&apos;t been signed out from
          all CVLs will automatically be put on hold.
        </b>
      </Text>
    </ConfirmCancelDialog>
  );
};
