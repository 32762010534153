import { Box, InternalLink, Spacer, Text, Title } from '@color/continuum';

import { AlertCard } from 'components/common/AlertCard';
import { VariantInterpretationChanges } from 'lib/types';

interface Props {
  variantInterpretationChanges: VariantInterpretationChanges[];
  holdDate: string;
}

export const NeedHrdfCard: React.FC<Props> = (props) => {
  const { variantInterpretationChanges, holdDate } = props;

  return (
    <AlertCard
      alertSeverity="warning"
      alertTitle="Variant Adjudication Committee changed variant interpretation"
    >
      <Box>
        <Title variant="headline3">
          Variant interpretation changed: Need updated health-related data file
        </Title>
        <Text>{`Report was put on hold by RHP on ${holdDate}`}</Text>
        <Text>
          The Variant Adjudication Committee changed:
          {variantInterpretationChanges.map((variantChanges) => (
            <Box key={variantChanges.variantId}>
              <Spacer variant="xSmall" />
              <InternalLink to={`/variants/${variantChanges.variantId}`}>
                {' '}
                {variantChanges.variantName}
              </InternalLink>
              <ul>
                {variantChanges.changes.map((change) => (
                  <li key={change.caption}>
                    <Text key={change.caption}>
                      {`${change.caption} from ${change.oldValue} to ${change.newValue}`}
                    </Text>
                  </li>
                ))}
              </ul>
            </Box>
          ))}
          This report has automatically been put on hold. Submit an updated health-related data file
          with corrected variant interpretation and evidence notes (if applicable).
        </Text>
      </Box>
    </AlertCard>
  );
};
