import { Box, ExternalLink, Spacer, Title } from '@color/continuum';
import { Keys, isObjKey } from '@color/lib';

import { config } from 'config';
import { AssociatedDisorders } from 'lib/constants';
import { Variant } from 'lib/types';

import {
  ClassificationInfo,
  CvlClassificationInfo,
  CvlClassificationInfoMap,
} from './ClassificationInfo';
import { VariantDetailTable } from './VariantDetailTable';

interface VariantDetailsProps {
  variant: Variant;
  cvl?: string;
}

interface Interpretation extends CvlClassificationInfo {
  cvl: string;
}

export const createClassificationInfoMap = (interpretations: Interpretation[]) => {
  return interpretations.reduce((memo: CvlClassificationInfoMap, item: Interpretation) => {
    return {
      ...memo,
      [item.cvl]: {
        classification: { displayName: item.classification, internalName: item.classification },
        classificationWriteUp: item.classificationWriteUp,
        variantDetailsLink: item.variantDetailsLink,
      },
    };
  }, {} as CvlClassificationInfoMap);
};

const buildExternalEditUrl = (variant: Variant, cvl: string | undefined) => {
  const disorderLongValue = isObjKey(variant.disorder, AssociatedDisorders)
    ? AssociatedDisorders[variant.disorder]
    : undefined;
  const fields = {
    cvl,
    gene: variant.gene,
    chromosome: variant.chromosome,
    position: variant.position,
    ref: variant.ref,
    alt: variant.alt,
    transcript: variant.transcript,
    ghgvs: variant.gHgvs,
    chgvs: variant.cHgvs,
    phgvs: variant.pHgvs,
    classification: variant.classification,
    disorder: disorderLongValue,
  };

  const queryString = (Object.keys(fields) as Keys<typeof fields>)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(fields[key] as string | number)}`;
    })
    .join('&');

  return `${config.VARIANT_JOTFORM}?${queryString}`;
};

export const VariantDetails: React.FC<VariantDetailsProps> = (props) => {
  const { variant, cvl } = props;

  return (
    <>
      <Title variant="headline3">Variant information</Title>
      {config.VARIANT_JOTFORM && cvl && (
        <ExternalLink
          to={buildExternalEditUrl(variant, cvl)}
          linkShouldOpenInCurrentTab={false}
          sx={(theme) => ({
            color: theme.palette.info.main,
            textDecoration: 'none',
          })}
        >
          Edit Interpretation
        </ExternalLink>
      )}
      <Spacer variant="small" />
      <VariantDetailTable variant={variant} />
      <div id="classifications" />
      <Spacer variant="xxxLarge" />
      <Title variant="headline3">Classification details</Title>
      <Spacer variant="small" />
      <Box>
        <ClassificationInfo
          classificationInfo={createClassificationInfoMap(
            // @ts-expect-error - There's some mismatched types here
            variant.cvlInterpretations
          )}
          rhpClassification={variant.classification}
          isReportable={variant.isReportable}
        />
      </Box>
      <Spacer variant="large" />
    </>
  );
};
