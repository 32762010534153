import { Box, Button, MenuItem, Text } from '@color/continuum';
import { useLocalStorageState } from '@color/lib';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { User } from 'oidc-client-ts';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';

import { DropdownMenu } from 'components/common/DropdownMenu/DropdownMenu';
import { UserInfo, useColorQuery } from 'lib/api';
import { OidcProvider } from 'lib/types';

interface InnerUserMenuProps {
  logout: () => void;
  user?: User;
}

/**
 * @rohittalwalkar
 * During the tests, there's a bad interaction with having both these hooks in the same component:
 * useColorQuery
 * useAuth0
 *
 * That's because useColorQuery has a dependancy on our authentication state, and mocking it out
 * causes the useAuth0 module mock to have isssues.  So, splitting the component into two pieces,
 * which essentially allows the useAuth0 to complete first and then lets us call useColorQuery.
 *
 * I'll come back and revisit this hack/workaround if we can figure out a better way to mock those hooks:
 * https://getcolor.atlassian.net/browse/ALLOFUS-2128
 */

const InnerUserMenu: React.FC<InnerUserMenuProps> = (props) => {
  const { logout, user } = props;

  const { isLoading, isError, error, data } = useColorQuery<UserInfo>(`/userinfo`);

  if (isError) {
    return <Text>{`${error}`}</Text>;
  }
  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  // @rohittalwalkar - useColorQuery might return "undefined"
  if (data === undefined) {
    return <Text>Loading...</Text>;
  }

  const organization = data.cvl;

  return (
    <DropdownMenu
      menuId="nav-user-menu"
      DropdownButton={
        <Button
          sx={{
            fontWeight: 'normal',
            minWidth: '256px',
            paddingY: 0,
            paddingRight: 3,
            paddingLeft: 2,
            textAlign: 'left',
          }}
          variant="text"
          endIcon={<ExpandMoreIcon />}
        >
          <Box display="flex" flexDirection="column" justifyContent="space-between" width="100%">
            <Box>{user && user.profile.name}</Box>
            <Box>{organization}</Box>
          </Box>
        </Button>
      }
    >
      <MenuItem to="/account" component={Link}>
        Account details
      </MenuItem>
      <MenuItem onClick={() => logout()}>Sign out</MenuItem>
    </DropdownMenu>
  );
};

/**
 * FIXME Break this into separate files. Color standard is one component per file.
 */
export const UserMenu = () => {
  const auth = useAuth();
  const [, setStoredOidcProvider] = useLocalStorageState<OidcProvider | undefined>(
    'oidcProvider',
    undefined
  );

  if (!auth.isAuthenticated || !auth.user) {
    return null;
  }
  return (
    <InnerUserMenu
      logout={() => {
        setStoredOidcProvider(undefined);
        auth.removeUser();
      }}
      user={auth.user}
    />
  );
};
