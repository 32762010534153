import {
  Column,
  DjangoTableWithPagination,
  IPaginatedResponse,
  PaginationOptions,
  TableBody,
  TableCell,
  TableOptions,
  TableProps,
  TableRow,
  Text,
} from '@color/continuum';

import { Status } from 'lib/constants';
import { ChallengeInfo, StatusInfo, Variant } from 'lib/types';
import { formatDateString } from 'lib/utils';

import { getVariantNomenclature } from '../util';

const columns: Column[] = [
  {
    id: 'last_classification_date',
    name: 'Date last classified',
    isSortable: true,
  },
  {
    id: 'gene',
    name: 'Gene',
    isSortable: true,
  },
  {
    id: 'variant',
    name: 'Variant',
    isSortable: false,
  },
  {
    id: 'classification',
    name: 'Classification',
    isSortable: false,
  },
  {
    id: 'CVL',
    name: 'CVL',
    isSortable: false,
  },
  {
    id: 'status',
    name: 'Status',
    isSortable: false,
  },
  {
    id: 'numReportsOnHold',
    name: '# reports on hold',
    isSortable: false,
  },
];

export interface Props extends Omit<TableProps, 'columns'> {
  isLoading: boolean;
  tableOptions: TableOptions;
  paginationOptions: PaginationOptions;
  djangoResponse: IPaginatedResponse<Variant> | undefined;
}

export const VariantsTable: React.FC<Props> = (props) => {
  const { djangoResponse } = props;

  const statusArray = [
    Status.Harmonized,
    Status.NonHarmonized,
    Status.Discordant,
    Status.Challenged,
  ];
  const findStatus = (slug: string): string | undefined => {
    return statusArray.find((status: StatusInfo) => status.slug === slug)?.fullName;
  };

  type VariantStatusProps = {
    status?: string;
    challengeInfo?: ChallengeInfo;
  };

  const VariantStatus: React.FC<VariantStatusProps> = ({ status, challengeInfo }) => {
    if (challengeInfo && challengeInfo !== null) {
      return (
        <>
          <Text>Challenged</Text>
          <Text>{`Reason: ${challengeInfo.fields.join(', ')}`}</Text>
        </>
      );
    }
    return <Text>{(status && findStatus(status)) || '--'}</Text>;
  };

  const tableRows = djangoResponse?.results.map((variant) => {
    return (
      <TableRow
        hover
        key={variant.id}
        onClick={() => window.open(`/variants/${variant.id}`, '_blank')}
        sx={{ cursor: 'pointer' }}
      >
        <TableCell>
          <Text>
            {variant.lastClassificationDate
              ? formatDateString(variant.lastClassificationDate)
              : 'N/A'}
          </Text>
        </TableCell>
        <TableCell>
          <Text>{variant.gene}</Text>
        </TableCell>
        <TableCell>
          <Text>{getVariantNomenclature(variant)}</Text>
        </TableCell>
        <TableCell>
          <Text>{variant.classification}</Text>
        </TableCell>
        <TableCell>
          <Text>{variant.cvls.map((CVL) => CVL.cvl).join(', ')}</Text>
        </TableCell>
        <TableCell>
          <VariantStatus status={variant.status} challengeInfo={variant.challengeInfo} />
        </TableCell>
        <TableCell>
          <Text>{variant.numReportsOnHold}</Text>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <DjangoTableWithPagination {...{ columns, ...props }}>
      <TableBody>{tableRows}</TableBody>
    </DjangoTableWithPagination>
  );
};
